import { Spinner } from '@themesberg/react-bootstrap'
import React from 'react'
import { Button } from 'react-bootstrap'
import CountryForm from '../../../CustomPayment/components/CountryForm'
import useBonusCountry from '../hooks/useBonusCountry'

const BonusCountry = ({
  data,
  setData,
  isTenant = false,
  details = false,
  values,
  setSelectedTab,
  handleSubmit,
  isEdit = false,
  bonusDetail,
  setEnableSubmit
}) => {
  const { search, setSearch, myCountries, loading, } = useBonusCountry(isTenant)
  return (
    <>
      <CountryForm
        data={data}
        setData={setData}
        search={search}
        setSearch={setSearch}
        myCountries={myCountries}
        isTenant={isTenant}
        loading={loading}
        details={details || values?.bonusType === 'balance'}
      />
      {!details &&
        <div
          className='mt-4 d-flex justify-content-between align-items-center'
        >
          <Button
            variant='outline-warning'
            onClick={() => {
              // Determine the selected tab based on the conditions
              let selectedTab;

              // If we are in edit mode and the bonus has been claimed
              if (isEdit && bonusDetail?.claimedCount) {
                // Check the type of bonus
                if (bonusDetail?.bonusType === 'deposit') {
                  selectedTab = 'payment';
                } else {
                  selectedTab = 'general';
                }
              } else {
                // Determine the tab based on the bonusType from values
                switch (values?.bonusType) {
                  case 'freespins':
                  case 'cashfreespins':
                    selectedTab = 'games';
                    break;
                  case 'balance':
                  case 'promotion':
                    selectedTab = 'general';
                    break;
                  default:
                    selectedTab = 'currency';
                }
              }

              // Set the selected tab
              setSelectedTab(selectedTab);
              setEnableSubmit(false)
            }}
          >
            Anterior
          </Button>

          <div>
            <Button
              variant='outline-success'
              onClick={() => {
                handleSubmit()
              }}
            >
              Salvar
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </div>
        </div>}
    </>
  )
}

export default BonusCountry
