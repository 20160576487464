import React from 'react'
import { Row, Col, Card, Button } from '@themesberg/react-bootstrap'
// import ClientFilter from '../../../components/ClientFilter'
// import PortalFilter from '../../../components/PortalFilter'
import Preloader from '../../../components/Preloader'
import DemographicDetails from './components/DemographicDetails'
import LivePlayers from './components/LivePlayers'
import TopPlayes from './components/TopPlayes'
import PlayerLiability from './components/PlayerLiability'
import KPISummary from './components/KPISummary'
import KPIReport from './components/KPIReport'
import GameReport from './components/GameReport'
import useDashboard from './hooks/useDashboard'
import UsersAccessAlertNotify from './components/UsersAccessAlertNotify'
// import Highcharts from 'highcharts'
// import PieChart from "highcharts-react-official";
// const options = {
// 	chart: {
// 	  type: "pie"
// 	},
// 	// series: []
//   };
export default () => {
  const {
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    reportsToShow,
    permissionKeys,
    loading,
    adminPermissions,
    elasticHealth,
    nonElasticReports
  } = useDashboard()

  return (
    <>
      {loading
        ? <Preloader />
        : (adminPermissions && permissionKeys.some(x => reportsToShow.indexOf(x) >= 0) &&
          (!elasticHealth || permissionKeys.some(x => nonElasticReports.indexOf(x) >= 0))
          ? (
            <>
              <div className='section-header-wrap mt-n3 mx-n3'>
                <Row>
                  <Col md={11} xs={11}>
                    <h2 className='title-text'>Painel Analítico</h2>
                  </Col>
                  <Col md={1} sx={1}>
                    <UsersAccessAlertNotify />
                  </Col>
                </Row>
              </div>
              {/* <PieChart highcharts={Highcharts} options={options} series={livePlayerData?.deviceLoggedIn.filter(d => d.device_type !== null).map(d => d.count) || []} /> */}
              {permissionKeys.includes('LivePlayerReport') &&
                <LivePlayers
                  selectedPortal={selectedPortal}
                  selectedClient={selectedClient}
                />}

              {/* {permissionKeys.includes('DemographReport') &&
                  <Card className='overflow-hidden'>
                    <DemographicDetails
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                    />
                  </Card>} */}

              {elasticHealth && permissionKeys.includes('PlayerManagementReport') &&
                <Card className='p-2 mt-4 mb-2'>
                  <TopPlayes
                    selectedPortal={selectedPortal}
                    selectedClient={selectedClient}
                  />
                </Card>}

              {elasticHealth && permissionKeys.includes('PlayerLiabilityReport') &&
                <Card className='p-2 mt-4 mb-2'>
                  <PlayerLiability
                    selectedPortal={selectedPortal}
                    selectedClient={selectedClient}
                  />
                </Card>}

              {permissionKeys.includes('KpiSummaryReport') &&
                <Card className='mt-4 mb-2'>
                  <KPISummary
                    selectedPortal={selectedPortal}
                    selectedClient={selectedClient}
                  />
                </Card>}

              {/* { permissionKeys.includes('KpiReport') &&
                  <Card className='mt-4 mb-2'>
                    <KPIReport
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                    />
                  </Card>} */}

              {permissionKeys.includes('GameReport') &&
                <Card className='mt-4 mb-2'>
                  <GameReport
                    selectedPortal={selectedPortal}
                    selectedClient={selectedClient}
                  />
                </Card>}
            </>
          )
          : (
            <>
              <Card className='p-2 mb-2'>
                <Row>
                  <Col>
                    <h3>Painel Analítico </h3>
                  </Col>
                </Row>
              </Card>

              <Card style={{ height: '79vh' }}>
                <img className='m-auto' src='/nodata2.png' alt='nodata' />
              </Card>
            </>
          ))}
    </>
  )
}
