
export const streamerVideosTutorials = [
  {
    title: "Video Aula 01",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_01.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_01_cover.jpg"
  },
  {
    title: "Video Aula 02",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_02.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_02_cover.jpg",
  },
  {
    title: "Video Aula 03",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_03.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_03_cover.jpg",
  },
  {
    title: "Video Aula 04",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_04.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_04_cover.jpg",
  },
  {
    title: "Video Aula 05",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_05.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_05_cover.jpg",
  },
  {
    title: "Video Aula 06",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_06.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_06_cover.jpg",
  },
  {
    title: "Video Aula 07",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_07.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_07_cover.jpg",
  },
  {
    title: "Video Aula 08",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_08.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_08_cover.jpg",
  },
  {
    title: "Video Aula 09",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_09.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_09_cover.jpg",
  },
  {
    title: "Video Aula 10",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_10.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_10_cover.jpg",
  },
  {
    title: "Video Aula 11",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_11.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_11_cover.jpg",
  },
  {
    title: "Video Aula 12",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_12.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_12_cover.jpg",
  },
  {
    title: "Video Aula 13",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_13.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_13_cover.jpg",
  },
  {
    title: "Video Aula 14",
    src: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_14.mp4",
    poster: "https://only-bts-prod-active-storage.s3.amazonaws.com/production/affiliates/scholl/ob_aula_14_cover.jpg",
  },
];