import { STATUS_LABEL } from "../../../utils/constant"

export const kycLabels = [
  { value: '', label: 'Kyc Status' },
  { value: 'APPROVED', label: STATUS_LABEL.APPROVED },
  { value: 'PENDING', label: STATUS_LABEL.PENDING },
  { value: 'REJECTED', label: STATUS_LABEL.REJECTED },
  { value: 'REQUESTED', label: STATUS_LABEL.REQUESTED },
  { value: 'RE_REQUESTED', label: STATUS_LABEL.RE_REQUESTED }
]

export const tableHeaders = [
  { label: 'Id', value: 'userId' },
  { label: 'Usuário', value: 'username' },
  { label: 'E-mail', value: 'email' },
  { label: 'Telefone', value: 'phoneNumber' },
  { label: 'Status', value: 'status' },
  { label: 'kyc status', value: 'kycStatus' },
  { label: 'Affiliado', value: 'affiliateId' },
  { label: 'Interno', value: 'is internal' },
  { label: 'Cadastrado em:', value: 'createdAt' },
  { label: 'action', value: 'action' }
]

export const tableUsersAccessHeaders = [
  { label: 'ID', value: 'userAccessId' },
  { label: 'Usuário', value: 'username' },
  { label: 'IP', value: 'loginIp' },
  { label: 'Iniciado em:', value: 'startedAt' },
  { label: 'Finalizado em:', value: 'finihedAt' },
  { label: 'Tempo HH:mm:ss', value: 'timeAtUnix' },
]

export const tableUsersAccessAlertHeaders = [
  { label: 'IP', value: 'loginIp', className: "text-left" },
  { label: 'Usuário', value: 'username', className: "text-left" },
  { label: 'Messagem', value: 'message' },
  { label: 'Status', value: 'status' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Ação', value: 'action', className: 'min-width-10-rem' },
]

export const tableUsersTemporarySuspensionHeaders = [
  { label: 'ID', value: 'userTemporarySuspensionId' },
  { label: 'Usuário', value: 'username' },
  { label: 'Tipo de Ação', value: 'actioneeType' },
  { label: 'ID da Ação', value: 'actioneeId' },
  { label: 'Usuário da Ação', value: 'actionee.email', className: "text-left" },
  { label: 'Status', value: 'isBlocked' },
  { label: 'Motivo', value: 'reason', className: "text-left" },
  { label: 'Suspenso em:', value: 'createdAt' },
  { label: 'Suspenso até:', value: 'blockedUntil' },
  { label: 'Reativado em:', value: 'unblockedAt' },
  { label: 'Reativado por: Tipo Ação', value: 'actioneeType' },
  { label: 'Reativado por: ID Ação', value: 'actioneeId', className: "text-right" },
  { label: 'Reativado por: Usuário da Ação', value: 'actionee.email', className: "text-left" },

]