
export const downloadBlob = (fileName, blobData) => {
  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute(
    'download',
    `${fileName}.xlsx`,
  );

  document.body.appendChild(link);
  link.click();

  link.remove();
}