import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "@themesberg/react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import useDebounce from "../../../hooks/useDebounce";

import { resolveErrors } from "../../../utils/errorTypes";
import { toast } from "../../../components/Toast";
import Button from "../../../components/Button";

import { Loading } from "../../../components/Preloader";
import PaginationComponent from "../../../components/Pagination";
import FormatDate from "../../../utils/formatDate";
import Trigger from "../../../components/OverlayTrigger";
import { getAdminUserViewReport } from "../../../utils/apiCalls";

export const tableUsersAccessHeaders = [
  { label: 'ID', value: 'adminUserViewReportId', className: "text-left" },
  { label: 'Usuário', value: 'username', className: "text-left" },
  { label: 'Tipo Ação', value: 'actioneeType', className: "text-right" },
  { label: 'ID da Ação', value: 'actioneeId' },
  { label: 'Usuário da Ação', value: 'actionee.adminUsername', },
  { label: 'E-mail da Ação', value: 'actionee.email' },
  { label: 'IP', value: 'ip', className: "text-left" },
  { label: 'Data', value: 'createdAt' },
]

export const AdminUserViewsReport = () => {
  const [userData, setUserData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState(searchParams.get("userId") || "");


  const loadAdminUserViews = useDebounce(async ({ loading, initialLoading, page, userId }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await getAdminUserViewReport({
        userId,
        pageNo: page || 1,
        limit: 15,
      })
      setUserData(res.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter visualizações de usuários"), "error");
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }, 400)

  const resetFilter = () => {
    setUserId('');
  }

  useEffect(() => {
    loadAdminUserViews({ userId, loading: true });
  }, [userId])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Visualizações de usuários</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col md={3} xs={11}>
            <Form.Control
              type="number"
              placeholder="User id"
              size="sm"
              value={userId}
              onChange={(event) => setUserId(event.target.value)}
            />
          </Col>

          <Col md={9} xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  size="sm"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">

        <div className='position-relative h-100'>
          <Table striped responsive hover size="sm" className="text-center mt-3">
            <thead className="thead-light">
              <tr>
                {tableUsersAccessHeaders.map((h, idx) => (
                  <th key={idx} className={h.className}> {h.label}{" "} </th>
                ))}
              </tr>
            </thead>

            <tbody >
              {(!initialLoading && !loading) &&
                userData?.result?.map(
                  ({
                    userId,
                    user,
                    adminUserViewReportId,
                    actionee,
                    actioneeType,
                    actioneeId,
                    ip,
                    createdAt,
                  }) => {
                    return (
                      <tr key={adminUserViewReportId}>
                        <td >{adminUserViewReportId}</td>
                        <td className="text-left"><Link className="text-link" to={`/admin/users/details/${userId}`}>{user.username}</Link></td>
                        <td className="text-right">{actioneeType}</td>
                        <td>{actioneeId}</td>
                        <td >{actionee.adminUsername}</td>
                        <td className="text-left">{actionee.email}</td>
                        <td className="text-left">{ip}</td>
                        <td>{FormatDate.default(createdAt)}</td>
                      </tr>
                    );
                  }
                )}

              {userData?.result?.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-danger text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {(initialLoading || loading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userData?.total || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.total !== 0 && !initialLoading && (
              <PaginationComponent
                className=""
                page={userData?.total < page ? setPage(1) : page}
                totalPages={userData?.totalPages}
                setPage={(page) => {
                  setPage(page);
                  loadAdminUserViews({ loading: true, page, userId });
                }}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};

export default AdminUserViewsReport;