import React, { useState } from 'react'
import { Col, Row } from '@themesberg/react-bootstrap'

import { useSelector } from 'react-redux';
import { streamerVideosTutorials } from './constants';
import { Modal } from 'react-bootstrap';

const VideoModal = ({ src, title, poster }) => {
  const [open, setOpen] = useState();

  const openVideo = () => {
    setOpen(true)
  }

  return (<>
    <div className='ratio ratio-16x9'>
      <video src={src} poster={poster} className=' bg-dark rounded-lg cursor-pointer' onClick={openVideo}></video>
    </div>
    <Modal show={open} onHide={() => setOpen(false)} fullscreen className='fullscreen-video-modal'>
      <Modal.Header closeButton title={title}><h5 className='mb-0'>{title}</h5></Modal.Header>
      <Modal.Body className='d-flex align-items-center'>
        <div className='ratio ratio-16x9'>
          <video src={src} poster={poster} className='ratio ratio-16x9 bg-dark rounded-lg' controls></video>
        </div>
      </Modal.Body>
    </Modal>
  </>)
}

export const StreamerVideoTutorialsPage = () => {

  const { adminDetails: { affiliate } } = useSelector(
    (state) => state.admins
  );

  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>
        <Row>
          <Col xs={12}>
            <h2 className='title-text'>
              Streamer video aulas
            </h2>
          </Col>
        </Row>
      </div>
      <div className='TableBg p-3 py-0 position-relative'>
        <div className='row flex-wrap'>
          {streamerVideosTutorials.map(({ src, title, poster }, key) => {
            return (
              <div className='col-md-4 col-6 p-2 p-md-3' key={key}>
                <div className='d-flex flex-column p-2 rounded-lg shadow-sm'>
                  <VideoModal src={src} title={title} poster={poster} />
                  <h6 className='mt-3 mb-0'>{title}</h6>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default StreamerVideoTutorialsPage;