import { Row, Col, Badge } from '@themesberg/react-bootstrap'
import React from 'react'
import {
  BONUS_TYPE_CONSTANTS,
  checkLabels,
  daysLabels,
  wageringRequirementType
} from './../components/CreateBonus/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Parser from 'html-react-parser'
import { formatDateYMD } from '../../../../utils/dateFormatter'
import "./generalDetail.scss"
const GeneralDetails = ({ bonusDetail }) => {
  return (
    <>
      <div className='FormsBg'>
        <Row>
          <Col sm={4}>
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Promotion Title</h3>
              </Col>
              <Col>
                <p>{bonusDetail?.promotionTitle?.EN}</p>
              </Col>
            </Row>

            {bonusDetail?.visibleInPromotions && bonusDetail?.bonusType !== 'promotion' &&
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Valid on Days</h3>
                </Col>
                <Col>
                  {daysLabels?.map((day) => {
                    return (
                      <Col
                        key={day}
                        className='d-flex'
                        style={{
                          verticalAlign: 'middle',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <p>{day}</p>
                        {bonusDetail?.validOnDays?.includes(day) && (
                          <div
                            className='rounded-circle mt-2 mx-2'
                            style={{
                              width: '10px',
                              height: '10px',
                              background: '#1aa509'
                            }}
                          />
                        )}
                      </Col>
                    )
                  })}
                </Col>
              </Row>}
            {bonusDetail?.bonusType !== 'joining' && <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Terms and Conditions</h3>
              </Col>
              <Col className='termsConditions'>
                {bonusDetail?.termCondition?.EN && Parser(bonusDetail?.termCondition?.EN)}
              </Col>
            </Row>}
          </Col>
          <Col sm={4}>
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Bonus Type</h3>
              </Col>
              <Col>
                {/* <p>{bonusType.filter((type)=>)}</p> */}
                <p>{bonusDetail?.bonusType === 'freespins' ? bonusDetail?.isSticky ? 'BONUS FREESPINS' : 'CASH FREESPINS' : bonusDetail?.bonusType?.toUpperCase()}</p>
              </Col>
            </Row>
            {bonusDetail?.bonusType === 'balance' &&
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Applied Bonus</h3>
                </Col>
                <Col>
                  <p>{bonusDetail?.appliedBonusTitle?.EN} ({bonusDetail?.appliedBonusId})</p>
                </Col>
              </Row>}
            {bonusDetail?.bonusType === 'freespins' &&
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Quantity</h3>
                </Col>
                <Col>
                  {/* <p>{bonusType.filter((type)=>)}</p> */}
                  <p>{bonusDetail?.quantity}</p>
                </Col>
              </Row>}

            {
              <>
                <Row>
                  <Col>
                    <h3 className='h6 text-nowrap'>Wagering Type</h3>
                  </Col>
                  <Col>
                    <p>{wageringRequirementType?.find(val => val.value === bonusDetail?.wageringRequirementType)?.label}</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className='h6 text-nowrap'>Wagering Multiplier </h3>
                  </Col>
                  <Col>
                    <p>{bonusDetail?.wageringMultiplier}</p>
                  </Col>
                </Row>
              </>}
            {bonusDetail?.bonusType !== 'promotion' &&
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Days To Clear</h3>
                </Col>
                <Col>
                  <p>{bonusDetail?.daysToClear}</p>
                </Col>
              </Row>}

            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Bonus Percentage</h3>
              </Col>
              <Col>
                <p>{bonusDetail?.depositBonusPercent || 'N/A'}</p>
              </Col>
            </Row>
            {checkLabels(bonusDetail?.bonusType).map(({ label, value }) => {
              return (
                <Row key={label}>
                  <Col>
                    <h3 className='h6 text-nowrap'>{label}</h3>
                  </Col>
                  <Col>
                    <Badge
                      className='mb-3'
                      bg={bonusDetail?.[value] ? 'success' : 'dark'}
                    >
                      <FontAwesomeIcon
                        icon={bonusDetail?.[value] ? faCheck : faTimes}
                      />
                    </Badge>
                  </Col>
                </Row>
              )
            })}
            {/* {!['depositCashback', 'wagering','joining'].includes(bonusDetail?.bonusType) &&
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Show Validity</h3>
              </Col>
              <Col>
                <Badge
                  className='mb-3'
                  bg={bonusDetail?.other?.showBonusValidity ? 'success' : 'dark'}
                >
                  <FontAwesomeIcon
                    icon={bonusDetail?.other?.showBonusValidity ? faCheck : faTimes}
                  />
                </Badge>
              </Col>
            </Row>} */}
            {/* {bonusDetail?.bonusType !== 'freespins' && bonusDetail?.bonusType !== 'promotion' && bonusDetail?.bonusType !== 'balance' && bonusDetail?.bonusType !== 'joining'&&
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>isSticky</h3>
              </Col>
              <Col>
                <Badge
                  className='mb-3'
                  bg={bonusDetail?.isSticky ? 'success' : 'dark'}
                >
                  <FontAwesomeIcon
                    icon={bonusDetail?.isSticky ? faCheck : faTimes}
                  />
                </Badge>
              </Col>
            </Row>} */}
          </Col>
          <Col sm={4}>
            {/* <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Admin</h3>
            </Col>
            <Col>
              <p>{`${bonusDetail?.adminUser?.firstName} ${bonusDetail?.adminUser?.lastName}`}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className='h6 text-nowrap'>Tenant</h3>
            </Col>
            <Col>
              <p>{`${bonusDetail?.tenant?.name}`}</p>
            </Col>
          </Row> */}
            <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Code</h3>
              </Col>
              <Col>
                <p>{bonusDetail?.bonusCode || 'N/A'}</p>
              </Col>
            </Row>
            {bonusDetail?.bonusType === BONUS_TYPE_CONSTANTS.JOINING && <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Visible on banner</h3>
              </Col>
              <Col>
                <Badge
                  className='mb-3'
                  bg={bonusDetail?.other?.isVisibleOnBanner ? 'success' : 'dark'}
                >
                  <FontAwesomeIcon
                    icon={bonusDetail?.other?.isVisibleOnBanner ? faCheck : faTimes}
                  />
                </Badge>
              </Col>
            </Row>}
            {bonusDetail?.bonusType !== 'joining' && <Row>
              <Col>
                <h3 className='h6 text-nowrap'>Description</h3>
              </Col>
              <Col>
                {bonusDetail?.description?.EN && Parser(bonusDetail?.description?.EN)}
              </Col>
            </Row>}
            {/* {(bonusDetail?.bonusType !== 'depositCashback' && bonusDetail?.bonusType !== 'wagering' &&  bonusDetail?.bonusType !== 'joining') &&
            <>
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Valid From</h3>
                </Col>
                <Col>
                  <p>{formatDateYMD(bonusDetail?.validFrom)}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Valid To</h3>
                </Col>
                <Col>
                  <p>{formatDateYMD(bonusDetail?.validTo)}</p>
                </Col>
              </Row>
            </>} */}
            {(bonusDetail?.bonusType === 'depositCashback' || bonusDetail?.bonusType === 'wagering') &&
              <Row>
                <Col>
                  <h3 className='h6 text-nowrap'>Time Period</h3>
                </Col>
                <Col>
                  <p>{parseInt(bonusDetail?.other?.timePeriod) === 1 ? 'Daily' : (parseInt(bonusDetail?.other?.timePeriod) === 7 ? 'Weekly' : 'Monthly')}</p>
                </Col>
              </Row>}
            {<Row>
              <Col>
                <h3 className='h6 text-nowrap'>Image</h3>
                <img src={`${bonusDetail?.imageUrl}`} height='200' width='300' alt='img' />
              </Col>
            </Row>}
          </Col>
        </Row>
      </div>

    </>
  )
}
export default GeneralDetails
