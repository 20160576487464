import { Modal, Form } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { temporarySuspendReasonSchema } from '../../../Tenant/Users/schemas'
import { userDisableReasons } from '../constants'
import classNames from 'classnames'
import DateRangePicker from '../../../../components/DateRangePicker'
import { addDays } from 'date-fns'
import { getReasonsForSuspension, temporarySuspendReactivate, temporarySuspendUserAccount } from '../../../../utils/apiCalls'
import { toast } from '../../../../components/Toast'
import Button from '../../../../components/Button'
import { resolveErrors } from '../../../../utils/errorTypes'
import { getUserStart } from '../../../../store/redux-slices/fetchData'
import { useDispatch } from 'react-redux'

const UserTemporarySuspensionButton = ({
  user,
  className,
  ...props
}) => {
  const [show, setShow] = useState();
  const userTemporarySuspension = user?.userTemporarySuspension && user?.userTemporarySuspension[0];
  const dispatch = useDispatch();
  const initialRangeDate = (endDate) => [{
    startDate: new Date(),
    endDate: endDate || new Date(),
    key: 'selection'
  }]
  const [dateRange, setDateRange] = useState(initialRangeDate())
  const [loading, setLoading] = useState(false);
  const [reasonsSuspensionList, setReasonsSuspensionList] = useState([]);

  const loadGetReasonsForSuspension = async () => {
    const res = await getReasonsForSuspension();
    setReasonsSuspensionList(res.data.data.result);
  }

  const handleSubmit = async (formData, { resetForm }) => {
    setLoading(true);
    try {
      await temporarySuspendUserAccount(formData);
      toast("Usuário suspenso com sucesso!", 'success');
      handleClose();
      resetForm();
      dispatch(getUserStart({ userId: user?.userId }));
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao suspender conta do usuário"), "error");
    } finally {
      setLoading(false);
    }
  }

  const handlerReactivateFromSuspension = async () => {
    setLoading(true);
    try {
      await temporarySuspendReactivate({ userId: user?.userId });
      toast("Usuário reativado com sucesso!", 'success');
      handleClose();
      dispatch(getUserStart({ userId: user?.userId }));
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao reativar conta do usuário"), "error");
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setShow(false);
    setDateRange(initialRangeDate());
  }

  useEffect(() => {
    loadGetReasonsForSuspension()
  }, [])

  return reasonsSuspensionList.length ? (
    <>
      <div className="col-xl-6 col-md-12 col-sm-6 col-12 mb-3">
        <Button loading={loading} onClick={() => userTemporarySuspension?.isBlocked ? handlerReactivateFromSuspension() : setShow(true)} className={classNames('w-100', className)} variant={classNames(!userTemporarySuspension ? 'outline-secondary' : 'outline-warning')} {...props}>
          {!userTemporarySuspension ? 'Suspender usuário' : 'Reativar usuário'}
        </Button>
      </div>
      <Formik
        initialValues={{
          userId: user?.userId,
          reasonSuspensionId: '',
          blockedUntil: '',
        }}
        validationSchema={temporarySuspendReasonSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <h5>Suspender temporariamente o usuário <br /> {user?.firstName || 'Player'}</h5>
            </Modal.Header>
            <Modal.Body>
              <div className='form-group'>
                <label
                  htmlFor='reasonSuspensionId'
                  className={errors.reason ? 'text-danger' : ''}
                >
                  Motivo<span className='text-danger'> *</span>
                </label>
                <Form.Select
                  name='reasonSuspensionId'
                  value={values.reason}
                  onChange={handleChange}
                  className={errors.reason ? 'border border-danger' : ''}
                >
                  <option value=''>Selecione o motivo</option>
                  {reasonsSuspensionList.map(({ reasonSuspensionId, reason }, key) => <option value={reasonSuspensionId} key={key}>{reason.PT || "N/A"}</option>)}
                </Form.Select>
                <ErrorMessage
                  component='div'
                  name='reasonSuspensionId'
                  className='text-danger mt-1'
                />
              </div>
              <div className='form-group mt-3'>
                <label
                  htmlFor='reason'
                >
                  Suspenso até<span className='text-danger'> *</span>
                </label>
                <DateRangePicker
                  state={dateRange}
                  setState={(date) => {
                    setDateRange(initialRangeDate(date.map(a => a.endDate)[0]));
                    setFieldValue('blockedUntil', date.map(a => a.endDate)[0].toISOString())
                  }}
                  minDate={addDays(new Date(), 0)}
                />
                <ErrorMessage
                  component='div'
                  name='blockedUntil'
                  className='text-danger mt-1'
                />
              </div>
            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
                <Button
                  loading={loading}
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Enviar
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  ) : null
}

export default UserTemporarySuspensionButton;
