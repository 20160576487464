import React, { useEffect, useState } from 'react';

import { getAffiliateLivesReport } from '../../../../utils/apiCalls';

import { Tab, Table, Tabs } from "@themesberg/react-bootstrap";
import FormatDate from '../../../../utils/formatDate';
import { Loading } from '../../../../components/Preloader';
import useDebounce from '../../../../hooks/useDebounce';
import UpdateButtonIcon from '../../../../components/UpdateButtonIcon';
import AffiliateStreamLogsTable from './AffiliateStreamLogs';
import PaginationComponent from '../../../../components/Pagination';
import { DashboardHeadTitle } from '../../../Affiliates/Dashboard/style';
import Button from '../../../../components/Button';
import { Col, Row } from 'react-bootstrap';
import { RiFileExcel2Fill } from "react-icons/ri";
import { downloadBlob } from '../../../../utils/downloadBlob';
import DateRangePicker from '../../../../components/DateRangePicker';
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter';

export const affiliateTableHeaders = [
  { label: 'Id da Live', value: 'liveId' },
  { label: 'Duração', value: 'timeReadable' },
  /*  { label: 'Visualizações', value: 'viewers' }, */
  { label: 'Visualizações Únicas', value: 'unique_total_viewers' },
  { label: 'Iniciado em:', value: 'startedAt' },
  { label: 'Finalizado em:', value: 'finishedAt' },
]

const AffiliateLives = ({ affiliate }) => {
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [data, setLiveData] = useState([]);
  const [activeKey, onSelect] = useState("lives");
  const [page, setPage] = useState(1);

  const [dateRange, setDateRange] = useState([{
    startDate: getDateDaysAgo(30),
    endDate: new Date(),
    key: 'selection'
  }])

  const getAffiliateLives = useDebounce(async ({ page, exportToExcel }) => {
    setLoading(!exportToExcel);
    if (exportToExcel) setExportLoading(true);
    try {
      const res = await getAffiliateLivesReport({
        streamerId: affiliate?.streamerId,
        pageNo: page || 1,
        limit: 15,
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        ...(exportToExcel ? { exportToExcel: true } : {})
      });
      if (exportToExcel) {
        downloadBlob(`${affiliate.username}_live_report_${new Date().toISOString()}`, res.data)
      } else {
        setLiveData(res.data.data)
      }
    } finally {
      setLoading(false);
      setExportLoading(false);
    }
  }, 400)

  const exportToExcel = () => {
    getAffiliateLives({ exportToExcel: true })
  }

  useEffect(() => {
    if (affiliate?.affiliateId) getAffiliateLives({ page })
  }, [affiliate, dateRange])

  if (!affiliate) return;

  return (
    <div className='TableBg mt-4 p-2'>

      <div className='position-relative h-100'>
        <Tabs {...{ activeKey, onSelect }} mountOnEnter unmountOnExit>
          <Tab
            eventKey='lives'
            title={'Lives'}
            className='mt-4 position-relative'
          >
            <DashboardHeadTitle className="mt-0 row px-2 pt-0 d-flex justify-content-between">
              <Col xs={12} md={6}>
                <DateRangePicker
                  state={dateRange} setState={setDateRange}
                  size='sm'
                  placement={"right"}
                />
              </Col>
              <Col xs={12} md={6} className='mt-2 mt-md-0'>
                <Row className='align-items-center justify-content-end'>
                  <Col xs={2} md={1} >
                    <UpdateButtonIcon
                      className={'ms-3'}
                      {...{ loading }}
                      onClick={getAffiliateLives}
                    />
                  </Col>
                  <Col md={3} xs={6}>
                    <Button className='btn-success w-100' onClick={exportToExcel} loading={exportLoading}> <RiFileExcel2Fill /> Exportar </Button>
                  </Col>
                </Row>
              </Col>
            </DashboardHeadTitle>
            <div className='position-relative h-100'>
              <Table striped responsive hover size="sm" className="text-center m-0">
                <thead className="thead-light">
                  <tr>
                    {affiliateTableHeaders.map((h, idx) => (
                      <th
                        key={idx}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {h.label}{" "}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {!loading &&
                    Boolean(data?.lives) &&
                    data?.lives?.map(
                      ({ liveId, timeReadable, viewers, unique_total_viewers, startedAt, finishedAt }) => {
                        return (
                          <tr key={liveId}>
                            <td>{liveId}</td>
                            <td>{timeReadable}</td>
                            {/* <td>{viewers.length}</td> */}
                            <td>{unique_total_viewers}</td>
                            <td>{startedAt ? FormatDate.default(startedAt) : "N/A"}</td>
                            <td>{finishedAt ? FormatDate.default(finishedAt) : "N/A"}</td>
                          </tr>
                        );
                      }
                    )}

                  {data?.lives?.length === 0 && !loading && (
                    <tr>
                      <td colSpan={13} className="text-danger text-center">
                        Nenhum registro de live encontrado!
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {loading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}

            </div>
            {data?.total > 0 && (
              <PaginationComponent
                page={data?.total < page ? setPage(1) : page}
                totalPages={data.totalPages}
                setPage={(page) => {
                  setPage(page);
                  getAffiliateLives({ loading: true, page });
                }}
              />
            )}
          </Tab>
          <Tab eventKey='logs' title="Logs" className='mt-4 position-relative'>
            <AffiliateStreamLogsTable {...{ affiliate }} />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default AffiliateLives
