import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "@themesberg/react-bootstrap";
import PaginationComponent from "../../../../components/Pagination";
import Trigger from "../../../../components/OverlayTrigger";

import { formatDateYMD, getDateByYear, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { ACTIVE_LABEL, ACTIVE_VALUE, STATUS_LABEL } from "../../../../utils/constant";
import FormatDate from "../../../../utils/formatDate";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { tableUsersTemporarySuspensionHeaders } from "../userConstant";
import useDebounce from "../../../../hooks/useDebounce";
import { resolveErrors } from "../../../../utils/errorTypes";
import { getUsersTemporaySuspension } from "../../../../utils/apiCalls";
import { toast } from "../../../../components/Toast";
import { Loading } from "../../../../components/Preloader";
import IpAddressInput from "../../../../components/IpAddressInput";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Button";

export const UsersTemporarySuspension = () => {
  const [userData, setUserData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState(searchParams.get("userId") || "");
  const [isBlocked, setIsBlocked] = useState('');
  const navigate = useNavigate();


  const loadUsersTemporarySuspension = useDebounce(async ({ loading, initialLoading, page, userId, isBlocked }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await getUsersTemporaySuspension({
        userId,
        isBlocked,
        pageNo: page || 1,
        limit: 15,
      })
      setUserData(res.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter suspensões de usuários"), "error");
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }, 400)

  const resetFilter = () => {
    setUserId('');
    setIsBlocked('');
  }

  useEffect(() => {
    loadUsersTemporarySuspension({ userId, isBlocked, loading: true });
  }, [userId, isBlocked])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Suspensões temporárias de usuários</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col md={3} xs={4}>
            <Form.Control
              type="number"
              placeholder="User id"
              size="sm"
              value={userId}
              onChange={(event) => setUserId(event.target.value)}
            />
          </Col>

          <Col md={3} xs={7}>
            <Form.Select value={isBlocked} onChange={({ target: { value } }) => setIsBlocked(value ? value : '')}>
              <option value={''} disabled className="d-none">Status</option>
              <option value={''}>Todos</option>
              <option value={'true'}>Suspenso</option>
              <option value={'false'}>Reativado</option>
            </Form.Select>
          </Col>
          <Col md={3} xs={11}>

          </Col>

          <Col md={3} xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  size="sm"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">

        <div className='position-relative h-100'>
          <Table striped responsive hover size="sm" className="text-center mt-3">
            <thead className="thead-light">
              <tr>
                {tableUsersTemporarySuspensionHeaders.map((h, idx) => (
                  <th key={idx}> {h.label}{" "} </th>
                ))}
              </tr>
            </thead>

            <tbody >
              {(!initialLoading && !loading) &&
                userData?.result?.map(
                  ({
                    userTemporarySuspensionId,
                    userId,
                    user,
                    actionee,
                    actioneeId,
                    actioneeType,
                    unblockedByActionee,
                    unblockedByActioneeId,
                    unblockedByActioneeType,
                    createdAt,
                    blockedUntil,
                    unblockedAt,
                    isBlocked,
                    reasonForSuspension,
                  }) => {
                    return (
                      <tr key={userId}>
                        <td >{userTemporarySuspensionId}</td>
                        <td className="text-left"><Link className="text-link" to={`/admin/users/details/${userId}`}>{user.username}</Link></td>
                        <td>{actioneeType}</td>
                        <td>{actioneeId}</td>
                        <td className="text-left">{actionee?.email}</td>
                        <td><span className="w-100" data-badge={ACTIVE_VALUE[isBlocked ? 'suspended' : 'reactived']}>{ACTIVE_LABEL[isBlocked ? 'suspended' : 'reactived']}</span></td>
                        <td className="text-left">{reasonForSuspension?.reason?.PT || 'N/A'}</td>
                        <td>{FormatDate.default(createdAt)}</td>
                        <td>{FormatDate.default(blockedUntil)}</td>
                        <td>{FormatDate.default(unblockedAt)}</td>
                        <td>{unblockedByActioneeType}</td>
                        <td>{unblockedByActioneeId}</td>
                        <td className="text-left">{unblockedByActionee?.email}</td>
                      </tr>
                    );
                  }
                )}

              {userData?.result?.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-danger text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {(initialLoading || loading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userData?.total || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.total !== 0 && !initialLoading && (
              <PaginationComponent
                className=""
                page={userData?.total < page ? setPage(1) : page}
                totalPages={userData?.totalPages}
                setPage={(page) => {
                  setPage(page);
                  loadUsersTemporarySuspension({ loading: true, page, userId, });
                }}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};
export default UsersTemporarySuspension;
