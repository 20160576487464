import React from "react";
import { Button, Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import PaginationComponent from "../../../components/Pagination";
import useAllUserListing from "./useAllUserListing";
import Trigger from "../../../components/OverlayTrigger";
import { kycLabels, tableHeaders } from "./userConstant";
import DateRangePicker from "../../../components/DateRangePicker";
import ActionMenu from "./ActionMenu";
import { getDateDaysAgo } from '../../../utils/dateFormatter'
import IpAddressInput from "../../../components/IpAddressInput";
import { ACTIVE_LABEL, ACTIVE_VALUE, STATUS_LABEL } from "../../../utils/constant";
import FormatDate from "../../../utils/formatDate";
import { Link } from "react-router-dom";

export default () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    searchByIp,
    setSearchByIp,
    userData,
    totalPages,
    navigate,
    loading,
    kycOptions,
    setKycOptions,
    state,
    setState,
    activeUserId,
    setUserId,
    setSortBy,
    setOrderBy,
    over,
    setOver,
    selected,
    sortBy,
    resetFilter
  } = useAllUserListing();

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Jogadores</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col xs={3}>
            <Form.Control
              type="search"
              placeholder="Pesquisar E-mail"
              size="sm"
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, "")
                )
              }
            />
          </Col>

          <Col xs={3}>
            <Form.Control
              type="number"
              placeholder="Pesquisar UserId"
              size="sm"
              value={activeUserId}
              onChange={(event) => setUserId(event.target.value)}
            />
          </Col>

          <Col xs={3}>
            <IpAddressInput
              type="text"
              placeholder="Pesquisar por IP"
              size="sm"
              value={searchByIp}
              onChange={(event) =>
                setSearchByIp(event.target.value)
              }
            />
          </Col>

          <Col xs={2}>
            <Form.Select
              onChange={(e) => setKycOptions(e.target.value)}
              value={kycOptions}
              size="sm"
            >
              {kycLabels.map((item) => {
                return (
                  <option key={`kyc Labels ${item?.value}`} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Form.Select>
          </Col>

          <Col xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  size="sm"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className="mt-2">
            <DateRangePicker
              state={state}
              setState={setState}
              size="sm"
              player
              transaction
              bonus
            />
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {tableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  onClick={() =>
                    (h.value === "email" ||
                      h.value === "userId" ||
                      h.value === "username") &&
                    setOrderBy(h.value)
                  }
                  style={{
                    cursor: "pointer",
                  }}
                  className={selected(h) ? "border-3 border border-blue" : ""}
                >
                  {h.label}{" "}
                  {selected(h) &&
                    (sortBy === "asc" ? (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleUp}
                        onClick={() => setSortBy("desc")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        style={over ? { color: "red" } : {}}
                        icon={faArrowCircleDown}
                        onClick={() => setSortBy("asc")}
                        onMouseOver={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                      />
                    ))}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? "cover-spin" : ""}>
            {!loading &&
              Boolean(userData) &&
              userData?.rows?.map(
                ({
                  email,
                  isActive,
                  userId,
                  kycStatus,
                  isInternalUser,
                  username,
                  phone,
                  createdAt,
                  affiliateId,
                  affiliate,
                  userTemporarySuspension: [userTemporarySuspension]
                }) => {
                  return (
                    <tr key={userId}>
                      <td>{userId}</td>

                      <td className="text-left">
                        {username ? (
                          <Trigger message={username}>
                            <span
                              className="text-link d-inline-block text-truncate w-100 cursor-pointer"
                              onClick={() => navigate(`/admin/users/details/${userId}`)}
                            >
                              {username}
                            </span>
                          </Trigger>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="text-left">{email}</td>

                      <td className="text-left1">{phone || "-"}</td>

                      <td><span className="w-100" data-badge={ACTIVE_VALUE[userTemporarySuspension ? 'suspended' : isActive]}>{ACTIVE_LABEL[userTemporarySuspension ? 'suspended' : isActive]}</span></td>
                      <td><span className="w-100" data-badge={String(kycStatus).toLowerCase()}>{STATUS_LABEL[kycStatus]}</span></td>

                      <td className="text-right">{affiliateId ? <Link to={`/admin/affiliate-details/${affiliateId}`} target='_blank'>{affiliate?.username}</Link> : ""}</td>
                      <td>{isInternalUser ? "Sim" : "Não"}</td>
                      <td>{FormatDate.default(createdAt)}</td>

                      <td>
                        <ActionMenu userId={userId} navigate={navigate} />
                      </td>
                    </tr>
                  );
                }
              )}

            {userData?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  Sem dados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de usuários ({userData?.count || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.count !== 0 && !loading && (
              <PaginationComponent
                page={userData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};
