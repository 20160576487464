import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "@themesberg/react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import useDebounce from "../../../hooks/useDebounce";
import { resolveErrors } from "../../../utils/errorTypes";
import { toast } from "../../../components/Toast";
import Button from "../../../components/Button";
import { Loading } from "../../../components/Preloader";
import PaginationComponent from "../../../components/Pagination";
import FormatDate from "../../../utils/formatDate";
import Trigger from "../../../components/OverlayTrigger";
import { getUserInfoUpdateReport } from "../../../utils/apiCalls";
import classNames from "classnames";
import Modal from "../../Modal/Modal";
import { USER_INFO_UPDATE_TYPE_LABEL } from "../../../utils/constant";
import { RiFileExcel2Fill } from "react-icons/ri";
import { downloadBlob } from "../../../utils/downloadBlob";

export const tableUsersAccessHeaders = [
  { label: 'ID', value: 'adminUserViewReportId', className: "text-left" },
  { label: 'Usuário', value: 'username', className: "text-left" },
  { label: 'Tipo de Atualização', value: 'userInfoUpdateType', className: "text-left" },
  { label: 'Tipo Ação', value: 'actioneeType', className: "text-right" },
  { label: 'ID da Ação', value: 'actioneeId' },
  { label: 'Usuário da Ação', value: 'actionee.adminUsername' },
  { label: 'E-mail da Ação', value: 'actionee.email' },
  { label: 'IP', value: 'ip', className: "text-left" },
  { label: 'Dados Alterados', value: '', },
  { label: 'Data', value: 'createdAt' },
]

const ViewUserDataUpdated = ({ userUpdatedDataBefore, userUpdatedDataAfter, username, date }) => {
  const [show, setShow] = useState(false);
  const hasAlterData = Object.keys(userUpdatedDataBefore).length && Object.keys(userUpdatedDataAfter).length;

  const open = () => setShow(true);
  const close = () => setShow(false);

  return (<>
    <span onClick={open} className={classNames(hasAlterData && "cursor-pointer text-link")}>{hasAlterData ? "Visualizar" : "Nenhum dado Alterado"}</span>
    <Modal
      show={show}
      centered={false}
      onHide={close}
      title={`Usuário ${username}, alterado em ${date}`}
      content={<>
        <div className="row">
          <div className="col-12 col-md-6">
            <h4 className="text-center">Antes</h4>
            <Table striped responsive hover size="sm" className="text-center mt-3">
              <tbody>
                {Object.keys(userUpdatedDataBefore).map(key => {
                  return <tr><td className="bold uppercase text-left">{key}</td><td className="text-left">{String(userUpdatedDataBefore[key])}</td></tr>
                })}
              </tbody>
            </Table>
          </div>
          <div className="col-12 col-md-6">
            <h4 className="text-center">Depois</h4>
            <Table striped responsive hover size="sm" className="text-center mt-3">
              <tbody>
                {Object.keys(userUpdatedDataAfter).map(key => {
                  return <tr><td className="bold uppercase text-left">{key}</td><td className="text-left">{String(userUpdatedDataAfter[key])}</td></tr>
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </>}
    />
  </>);
};

export const UserInfoUpdatedReport = () => {
  const [userData, setUserData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState(searchParams.get("userId") || "");
  const [userInfoUpdateType, setUserInfoUpdateType] = useState('');
  const [exportLoading, setExportLoading] = useState(false);

  const loadUsersInfoUpdated = useDebounce(async ({ loading, initialLoading, page, userId = '', userInfoUpdateType = '', exportToExcel }) => {
    if (loading) setLoading(true);
    if (exportToExcel) setExportLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await getUserInfoUpdateReport({
        userId,
        userInfoUpdateType,
        pageNo: page || 1,
        limit: 15,
        ...(exportToExcel ? { exportToExcel: true } : {})
      })
      if (exportToExcel) {
        downloadBlob(`user_infor_update_report_${new Date().toISOString()}`, res.data);
      } else {
        setUserData(res.data.data);
      }
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter lista de atualizações de usuários"), "error");
    } finally {
      setLoading(false);
      setInitialLoading(false);
      setExportLoading(false);
    }
  }, 400);

  const exportToExcel = () => {
    loadUsersInfoUpdated({ exportToExcel: true })
  }

  const resetFilter = () => {
    setUserId('');
    setUserInfoUpdateType('');
  }

  useEffect(() => {
    loadUsersInfoUpdated({ userId, userInfoUpdateType, loading: true });
  }, [userId, userInfoUpdateType])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Atualizações de usuários</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col md={3} xs={12}>
            <Form.Control
              type="number"
              placeholder="User id"
              size="sm"
              value={userId}
              onChange={(event) => setUserId(event.target.value)}
            />
          </Col>
          <Col md={3} xs={12}>
            <Form.Select value={userInfoUpdateType} onChange={({ target: { value } }) => setUserInfoUpdateType(value ? value : '')}>
              <option value={''} disabled className="d-none">Tipo de Atualização</option>
              <option value={''}>Todos</option>
              {Object.keys(USER_INFO_UPDATE_TYPE_LABEL).map((key) => {
                return <option value={key} key={key}>{USER_INFO_UPDATE_TYPE_LABEL[key]}</option>
              })}
            </Form.Select>
          </Col>
          <Col md={4} xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  size="sm"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
          <Col md={2} xs={11}>
            <Button className='btn-success w-100' onClick={exportToExcel} loading={exportLoading}> <RiFileExcel2Fill /> Exportar </Button>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">

        <div className='position-relative h-100'>
          <Table striped responsive hover size="sm" className="text-center mt-3">
            <thead className="thead-light">
              <tr>
                {tableUsersAccessHeaders.map((h, idx) => (
                  <th key={idx} className={h.className}> {h.label}{" "} </th>
                ))}
              </tr>
            </thead>

            <tbody >
              {(!initialLoading && !loading) &&
                userData?.result?.map(
                  ({
                    userId,
                    user,
                    userInfoUpdateType,
                    userInfoUpdateReportId,
                    actionee,
                    actioneeType,
                    actioneeId,
                    ip,
                    userUpdatedDataBefore,
                    userUpdatedDataAfter,
                    createdAt,
                  }) => {
                    return (
                      <tr key={userInfoUpdateReportId}>
                        <td >{userInfoUpdateReportId}</td>
                        <td className="text-left"><Link className="text-link" to={`/admin/users/details/${userId}`}>{user.username}</Link></td>
                        <td className="text-left">{USER_INFO_UPDATE_TYPE_LABEL[userInfoUpdateType] || 'N/A'}</td>
                        <td className="text-right">{actioneeType}</td>
                        <td >{actioneeId}</td>
                        <td >{actionee.adminUsername}</td>
                        <td className="text-left">{actionee.email}</td>
                        <td className="text-left">{ip}</td>
                        <td><ViewUserDataUpdated {...{ userUpdatedDataBefore, userUpdatedDataAfter, username: user.username, date: FormatDate.default(createdAt) }} /></td>
                        <td>{FormatDate.default(createdAt)}</td>
                      </tr>
                    );
                  }
                )}

              {userData?.result?.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-danger text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {(initialLoading || loading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userData?.total || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.total !== 0 && !initialLoading && (
              <PaginationComponent
                className=""
                page={userData?.total < page ? setPage(1) : page}
                totalPages={userData?.totalPages}
                setPage={(page) => {
                  setPage(page);
                  loadUsersInfoUpdated({ loading: true, page, userId });
                }}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};

export default UserInfoUpdatedReport;