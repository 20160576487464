import React from 'react';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
// import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
// import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded'
import {
  Button,
} from '@themesberg/react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { PlusOneRounded } from '@mui/icons-material';
const ActionMenu = (props) => {
  const { handleShow, isHidden, isActive, name, index, handleStatusShow, gameSubCategory, gameSubCategoryId, isGlobal, handleDeleteItems } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      {!isHidden({
        module: {
          key: 'CasinoManagement',
          value: 'U',
        },
      }) ||
        !isHidden({
          module: {
            key: 'CasinoManagement',
            value: 'T',
          },
        }) ? (
        <>
          <Button
            variant='btn text-btn'
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MoreVertRoundedIcon />
          </Button>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={handleClose}>
              <Button
                variant='btn text-btn'
                onClick={() =>
                  handleShow(
                    'Edit Sub',
                    gameSubCategory.rows[index],
                  )
                }
                hidden={isHidden({
                  module: {
                    key: 'CasinoManagement',
                    value: 'U',
                  },
                })}
              >
                <BorderColorRoundedIcon />
                <span className='text'>Edit</span>
              </Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>

              <Button
                variant='btn text-btn'
                onClick={() =>
                  handleStatusShow(
                    gameSubCategoryId,
                    isActive,
                    name,
                  )
                }
                hidden={isHidden({
                  module: {
                    key: 'CasinoManagement',
                    value: 'T',
                  },
                })}
              >
                {
                  !isActive ?
                    <>
                      <CheckCircleRoundedIcon />
                      <span className='text'>Set Status Active</span>
                    </>
                    :
                    <>
                      <CancelRoundedIcon />
                      <span className='text'>Set Status In-Active</span>
                    </>
                }

              </Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button
                variant='btn text-btn'
                disabled={isGlobal}
                onClick={() => handleDeleteItems(gameSubCategoryId)}
                hidden={isHidden({
                  module: {
                    key: 'CasinoManagement',
                    value: 'T',
                  },
                })}
              >
                <DeleteForeverOutlinedIcon />
                <span className='text'>Delete</span>
              </Button>
            </MenuItem>

            {/* !isGlobal&& */<MenuItem onClick={handleClose}>
              <Button
                variant='btn text-btn'
                // disabled={isGlobal}
                onClick={() => navigate(`/admin/casino-sub-category/addGames/${gameSubCategoryId}`, { state: { subCategoryName: name } })}
                hidden={isHidden({
                  module: {
                    key: 'CasinoManagement',
                    value: 'T',
                  },
                })}
              >
                <PlusOneRounded />
                <span className='text'>Add Games</span>
              </Button>
            </MenuItem>}

          </Menu></>) : 'NA'}
    </>
  )
};

export default ActionMenu;