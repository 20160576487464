import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "@themesberg/react-bootstrap";
import Trigger from "../../../../components/OverlayTrigger";
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { tableUsersAccessAlertHeaders } from "../userConstant";
import useDebounce from "../../../../hooks/useDebounce";
import { resolveErrors } from "../../../../utils/errorTypes";
import { getUsersAccessAlert, readUserAccessAlert } from "../../../../utils/apiCalls";
import { toast } from "../../../../components/Toast";
import { Loading } from "../../../../components/Preloader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Button";
import DateRangePicker from "../../../../components/DateRangePicker";
import { AdminsRoutes } from "../../../../routes";
import { ACTIVE_LABEL, ACTIVE_VALUE, USER_ACCESS_FRAUD_STATUS_CLASS_COLOR, USER_ACCESS_FRAUD_STATUS_LABEL } from "../../../../utils/constant";
import FormatDate from "../../../../utils/formatDate";
import { BsCheck } from "react-icons/bs";
import PaginationComponent from "../../../../components/Pagination";

const getMessage = (status, { user, loginIpCount, loginIp, userCount }) => {
  const m = {
    1: `O usuário ${user.username} utilizou ${loginIpCount} IPs diferentes.`,
    2: `O IP ${loginIp} foi utilizado por ${userCount} usuários diferentes.`,
  }
  return m[status];
};

const ReadButton = ({ readed, userAccessAlertId, setUserData }) => {
  const [loading, setLoading] = useState(false);

  const handleReadAlert = async () => {
    setLoading(true);
    try {
      const res = await readUserAccessAlert({ userAccessAlertId });
      toast('Marcado como lido!', 'success');
      setUserData(state => {
        const result = state.result.map(e => {
          if (e.userAccessAlertId == res.data.data.result.userAccessAlertId) {
            return { ...e, ...res.data.data.result }
          }
          return e
        })
        return { ...state, result };
      })
    } catch (err) {

    } finally {
      setLoading(false);
    }
  }



  return readed
    ? <span className="w-100" data-badge={ACTIVE_VALUE[readed]}>Lido <BsCheck /></span>
    : <Button size='sm' variant='danger' className='w-100' loading={loading} onClick={handleReadAlert}>Marcar como Lida</Button>
}

export const UsersAccessAlert = () => {
  const [userData, setUserData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const initialRangeDate = (reset) => [{
    startDate: !reset && searchParams.get("startDate") ? new Date() : getDateDaysAgo(7),
    endDate: !reset && searchParams.get("startDate") ? new Date() : new Date(),
    key: 'selection'
  }]
  const [dateRange, setDateRange] = useState(initialRangeDate)

  const loadUsersAccess = useDebounce(async ({ loading, initialLoading, page }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await getUsersAccessAlert({
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        pageNo: page || 1,
        limit: 15,
      })
      setUserData(res.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter alertas de acessos de usuários"), "error");
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }, 400)

  useEffect(() => {
    loadUsersAccess({ loading: true });
  }, [dateRange])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Alerta de acessos de usuários</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col md={3} xs={4}>
            <DateRangePicker
              state={dateRange} setState={setDateRange}
              size='sm'
            />
          </Col>

          <Col md={3} xs={7}>
          </Col>

          <Col md={6} xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Atualizar">
                <Button
                  size="sm"
                  onClick={() => {
                    navigate(AdminsRoutes.UsersAccessAlert, { replace: true });
                    setDateRange(initialRangeDate(true))
                  }}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">

        <div className='position-relative h-100'>
          <Table striped responsive hover size="sm" className="text-center mt-3">
            <thead className="thead-light">
              <tr>
                {tableUsersAccessAlertHeaders.map((h, idx) => (
                  <th key={idx} className={h.className}> {h.label}{" "} </th>
                ))}
              </tr>
            </thead>

            <tbody >
              {(!initialLoading && !loading) &&
                userData?.result?.map(
                  ({
                    userAccessAlertId,
                    userId,
                    user,
                    loginIp,
                    loginIpCount,
                    status,
                    userCount,
                    readed,
                    createdAt
                  }) => {
                    return (
                      <tr key={userId}>
                        <td className="text-left">{loginIp ? <Link className="text-link" to={`${AdminsRoutes.UsersAccess}?${new URLSearchParams({ loginIp, startDate: dateRange.map(a => a.startDate), endDate: dateRange.map(a => a.endDate) }).toString()}`}>{loginIp}</Link> : "N/A"}</td>
                        <td className="text-left">{userId ? <Link className="text-link" to={`${AdminsRoutes.UsersAccess}?${new URLSearchParams({ userId }).toString()}`}>{user.username}</Link> : userCount ? userCount : "N/A"}</td>
                        <td className="text-left">{getMessage(status, { user, loginIp, userCount, loginIpCount })}</td>
                        <td><span className='w-100' data-badge={USER_ACCESS_FRAUD_STATUS_CLASS_COLOR[String(status).toUpperCase()]}>{USER_ACCESS_FRAUD_STATUS_LABEL[String(status).toUpperCase()]}</span></td>
                        <td>{FormatDate.toDateHour(createdAt)}</td>
                        <td className="min-width-10-rem"><ReadButton {...{ readed, userAccessAlertId, setUserData }} /></td>
                      </tr>
                    );
                  }
                )}

              {userData?.result?.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-danger text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {(initialLoading || loading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userData?.total || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.total !== 0 && !initialLoading && (
              <PaginationComponent
                className=""
                page={userData?.total < page ? setPage(1) : page}
                totalPages={userData?.totalPages}
                setPage={(page) => {
                  setPage(page);
                  loadUsersAccess({ loading: true, page });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersAccessAlert;
