import * as Yup from 'yup'
import "yup-phone";

export const userSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Email inválido').max(50).required('Email é obrigatório'),
    firstName: Yup.string().min(3, 'É obrigatório no mínimo 3 caracteres').max(50, 'É permitido no máxmo 50 caracteres').required('Primeiro nome é obrigatório'),
    lastName: Yup.string().min(3, 'É obrigatório no mínimo 3 caracteres').max(50, 'É permitido no máxmo 50 caracteres'),
    phone: Yup.string(),
    dateOfBirth: Yup.date().max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), 'Must Be 18 Years Old').nullable(),
    genderInt: Yup.number().nullable(),
    username: Yup.string().max(20, 'É permitido no máxmo 20 caracteres').min(3, 'É obrigatório no mínimo 3 caracteres').required('User Name Required'),
    address: Yup.string().max(100, 'É permitido no máxmo 100 caracteres'),
    city: Yup.string().max(50, 'É permitido no máxmo 50 caracteres').nullable()/* .required('City Required') */,
    zipCode: Yup.string(),
    currencyCode: Yup.string(),
    countryCode: Yup.string().nullable(),
    cpf: Yup.string('Informe um CPF válido')
      .test('valid-cpf', 'Informe um CPF válido', (value) => {
        if (!value) return false; // Reject if CPF is empty
        const match = value.match(/^\d{3}\.?\d{3}\.?\d{3}\-?\d{2}$/);
        if (!match) return false; // Reject if CPF format is invalid
        const cpf = match[0].replace(/\.|-/g, '');
        if (cpf.length !== 11) return false; // Reject if CPF length is not 11 characters
        return true; // CPF is valid
      }),
    rolloverType: Yup.number().nullable().when(["rolloverMultiplier"], (value, yup) => {
      return value > 0 ? yup.required("O tipo de rollover é obrigatório").min(1, "Selecione uma opção") : yup;
    }),
    rolloverMultiplier: Yup.number().min(0, "O valor minímo é 0"),
  })
