import React, { useEffect, useState } from 'react'
import {
  Form as BForm,
  Button,
  Col,
  InputGroup,
  Row
} from 'react-bootstrap'
import { ErrorMessage } from 'formik'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from 'react-redux'
import Trigger from '../../../../../components/OverlayTrigger'
import { getAllBonusStart } from '../../../../../store/redux-slices/admins'
import { bonusType, BONUS_TYPE_CONSTANTS, checkLabels } from './constants'
import ReactQuill from 'react-quill'
import DateRangePicker from '../../../../../components/DateRangePicker'
import classNames from 'classnames'

const GeneralForm = ({
  isEdit,
  isClone,
  touched,
  errors,
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  setFieldValue,
  bonusDetail,
  navigate,
  setGameIds,
  setEnableSubmit,
  appliedBonusOptions,
  data,
  setData,
  handleImagePreview,
  preview,
  state, setState
}) => {
  const { bonusList } = useSelector((state) => state.admins)
  const dispatch = useDispatch()

  // const isAlreadyExistsBonus = (type) => {
  //   return bonusList?.rows.find((bonus)=>{
  //     return bonus.bonusType === 
  //   })
  // }

  const isAlreadyLoyaltyBonusExist = (bonusType) => {
    if (bonusType === BONUS_TYPE_CONSTANTS.LOYALTY) {
      return bonusList?.rows?.find((bonus) => bonus.bonusType === BONUS_TYPE_CONSTANTS.LOYALTY)
    } else {
      return false
    }
  }

  useEffect(() => {
    dispatch(getAllBonusStart({
      limit: '15',
      pageNo: 1,
      search: '',
      bonusType: '',
      isActive: ''
    }))
  }, [])

  useEffect(() => {
    if ((isEdit) && appliedBonusOptions?.[0] && appliedBonusOptions?.[0]?.label?.EN !== '') {
      setFieldValue('appliedBonusId', appliedBonusOptions.filter(
        (opt) => JSON.parse(opt.value) === JSON.parse(bonusDetail?.appliedBonusId)
      ))
    }
  }, [appliedBonusOptions])

  useEffect(() => {
    if (bonusDetail?.gameIds && setGameIds) {
      setGameIds(bonusDetail?.gameIds)
    }
  }, [bonusDetail])

  const [err, setErr] = useState('')
  const [errDesc, setErrDesc] = useState('')

  return (
    <div className='FormsBg'>
      <Row>
        {!errors.imageUrl && (preview?.imageUrl?.image_preview || bonusDetail?.imageUrl) &&
          <Col className={classNames("mb-2", (preview?.imageUrlMobile?.image_preview || bonusDetail?.imageUrlMobile) ? "col-9" : "col12")}>
            <label htmlFor="">Banner</label>
            <img
              src={(preview?.imageUrl?.image_preview && values?.imageUrl) ? (preview?.imageUrl?.image_preview) : bonusDetail?.imageUrl && !isClone ? bonusDetail?.imageUrl : null}
              className='mt-2 border-0 bonus-banner-image'
              alt='bonus-img'
            />
          </Col>
        }
        {!errors.imageUrlMobile && (preview?.imageUrlMobile?.image_preview || bonusDetail?.imageUrlMobile) &&
          <Col className='mb-2 col-3'>
            <label htmlFor="">Banner Mobile</label>
            <img
              src={(preview?.imageUrlMobile?.image_preview && values?.imageUrlMobile) ? (preview?.imageUrlMobile?.image_preview) : bonusDetail?.imageUrlMobile && !isClone ? bonusDetail?.imageUrlMobile : null}
              className='mt-2 border-0 bonus-banner-image'
              alt='bonus-img'
            />
          </Col>
        }
      </Row>
      <Row className=' mt-3 mb-3'>
        <Col sm={4} className='mb-3'>
          <label
            htmlFor='promotionTitle'
            className={
              touched.promotionTitle && errors.promotionTitle
                ? 'text-danger'
                : ''
            }
          >
            Título<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.promotionTitle && errors.promotionTitle
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='promotionTitle'
              placeholder='Digite o nome do bônus'
              defaultValue={values.promotionTitle}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                setData({
                  ...data,
                  promoTitle: {
                    ...data?.promoTitle,
                    EN: e.target.value
                  }
                })
                handleChange(e)
              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='promotionTitle'
            className='text-danger'
          />
        </Col>

        <Col sm={4} className='mb-3'>
          <label
            htmlFor='bonusType'
            className={
              touched.bonusType && errors.bonusType ? 'text-danger' : ''
            }
          >
            Tipo de bônus<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.bonusType && errors.bonusType
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Select
              name='bonusType'
              disabled={isEdit || isClone}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e)
                setGameIds([])
                if (e.target.value === BONUS_TYPE_CONSTANTS.JOINING) {
                  setFieldValue('isSticky', false)
                }
                setFieldValue('currency', {
                  BRL: {
                    // maxBonusThreshold: '',
                    minDeposit: '',
                    maxWinAmount: '',
                    // zeroOutThreshold: '',
                    minBalance: '',
                    joiningAmount: ''
                  }
                })
                setEnableSubmit(false)
                setFieldValue('wageringRequirementType', 'bonus')
                if (e.target.value === BONUS_TYPE_CONSTANTS.LOYALTY) {
                  setFieldValue('currency', {
                    BRL: { coinEquivalentTo: '' },
                  })
                }
                if (e.target.value !== BONUS_TYPE_CONSTANTS.JOINING) {
                  setFieldValue('isVisibleOnBanner', false)
                }
              }}
              value={values.bonusType}
            >
              <option value='' disabled key=''>
                Selecione o tipo de bônus
              </option>
              {bonusType.map((item) => {
                if (isAlreadyLoyaltyBonusExist(item.value)) return false
                else {
                  return (
                    <option key={`bonusType ${item.id}`} value={item.value}>
                      {item.label}
                    </option>
                  )
                }
              })}
            </BForm.Select>
          </InputGroup>
          <ErrorMessage
            component='div'
            name='bonusType'
            className='text-danger'
          />
        </Col>


        <Col sm={4} className='mb-3'>
          <label
            htmlFor='daysToClear'
            className={
              touched.daysToClear && errors.daysToClear ? 'text-danger' : ''
            }
          >
            Dias de expiração após ativação do bônus<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.daysToClear && errors.daysToClear
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='daysToClear'
              type='number'
              min={1}
              // disabled={isEdit}
              placeholder='Enter Days To Clear'
              value={values.daysToClear}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='daysToClear'
            className='text-danger'
          />
        </Col>

      </Row>

      <Row>

        <Col sm={4} className='mb-3'>
          <label
            htmlFor='wageringMultiplier'
            className={
              touched.wageringMultiplier && errors.wageringMultiplier
                ? 'text-danger'
                : ''
            }
          >
            Multiplicador de apostas<span className='text-danger'> *</span>
          </label>
          <InputGroup
            className={
              touched.wageringMultiplier && errors.wageringMultiplier
                ? 'border border-danger'
                : ''
            }
          >
            <BForm.Control
              name='wageringMultiplier'
              type='number'
              min={1}
              placeholder='Enter Wagering Multiplier'
              value={values.wageringMultiplier}
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e)

              }}
            />
          </InputGroup>
          <ErrorMessage
            component='div'
            name='wageringMultiplier'
            className='text-danger'
          />
        </Col>
        <Col sm={4} className='mb-3'>
          <label>
            Data de expiração do bônus na plataforma
          </label>
          <DateRangePicker
            state={state} setState={setState} transaction bonus
          />
        </Col>
      </Row>

      {[BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT, BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT, BONUS_TYPE_CONSTANTS.JOINING, BONUS_TYPE_CONSTANTS.DEPOSIT].includes(values.bonusType) && (
        <Row>
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='depositBonusPercent'
              className={
                touched.depositBonusPercent && errors.depositBonusPercent
                  ? 'text-danger'
                  : ''
              }
            >
              Porcentagem de bônus
              <span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched.depositBonusPercent && errors.depositBonusPercent
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                name='depositBonusPercent'
                type='number'
                min={1}
                placeholder={'Enter Bonus Percentage'}
                defaultValue={values.depositBonusPercent}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name='depositBonusPercent'
              className='text-danger'
            />
          </Col>
          <Col sm={4} className='mb-3'>
            <label
              htmlFor='depositBonusCode'
              className={
                touched?.bonusCode && errors?.bonusCode
                  ? 'text-danger'
                  : ''
              }
            >
              Código do bônus<span className='text-danger'> *</span>
            </label>
            <InputGroup
              className={
                touched?.bonusCode && errors?.bonusCode
                  ? 'border border-danger'
                  : ''
              }
            >
              <BForm.Control
                id='bonusCode'
                name={`bonusCode`}
                type='text'
                placeholder='Enter Bonus Code'
                value={values?.bonusCode}
                onInput={handleChange}
                onBlur={handleBlur}
                onChange={(e) => {
                  console.log('e', e)
                  e.target.value = e.target.value.toUpperCase();
                  handleChange(e);
                }}
              />
            </InputGroup>
            <ErrorMessage
              component='div'
              name={`bonusCode`}
              className='text-danger'
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col className='mb-3'>
          <label htmlFor='imageUrl'>
            Banner<span className='text-danger'> *</span>
          </label>
          <InputGroup>
            <BForm.Control
              name='imageUrl'
              type='file'
              placeholder='Image'
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('imageUrl', event.currentTarget.files[0])
                handleImagePreview('imageUrl', event)
              }}
            />
          </InputGroup>
          <ErrorMessage component='div' name='imageUrl' className='text-danger' />
        </Col>
        <Col className='mb-3'>
          <label htmlFor='imageUrlMobile'>
            Banner Mobile<span className='text-danger'> *</span>
          </label>
          <InputGroup>
            <BForm.Control
              name='imageUrlMobile'
              type='file'
              placeholder='Image'
              onInput={handleChange}
              onBlur={handleBlur}
              onChange={(event) => {
                setFieldValue('imageUrlMobile', event.currentTarget.files[0])
                handleImagePreview('imageUrlMobile', event)
              }}
            />
          </InputGroup>
          <ErrorMessage component='div' name='imageUrlMobile' className='text-danger' />
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
          <label
            htmlFor='termCondition'
            className={
              ((touched?.termCondition && (err || errors.termCondition))) ? 'text-danger' : ''
            }
          >
            Termos e Condições<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='termCondition'
            placeholder='Enter Terms and Conditions'
            value={values.termCondition}
            onChange={(e) => {
              setFieldValue('termCondition', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                terms: {
                  ...data?.terms,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErr('')}
          />

          {err &&
            <div className='text-danger'>{err}</div>}

          {!err &&
            <ErrorMessage
              component='div'
              name='termCondition'
              className='text-danger'
            />}
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col>
          <label
            htmlFor='description'
            className={
              ((touched?.description && (errDesc || errors.description))) ? 'text-danger' : ''
            }
          >
            Descrição<span className='text-danger'> *</span>
          </label>
          <ReactQuill
            name='description'
            placeholder='Enter Description'
            value={values.description}
            onChange={(e) => {
              setFieldValue('description', e.replace(/\s/g, '&nbsp;'))
              setData({
                ...data,
                desc: {
                  ...data?.desc,
                  EN: e.replace(/\s/g, '&nbsp;')
                }
              })
            }}
            onKeyDown={() => setErrDesc('')}
          />

          {errDesc &&
            <div className='text-danger'>{errDesc}</div>}

          {!errDesc &&
            <ErrorMessage
              component='div'
              name='description'
              className='text-danger'
            />}
        </Col>
      </Row>

      <Row>
        {
          [BONUS_TYPE_CONSTANTS.JOINING].includes(values.bonusType) &&
          <Col xs={12} className='d-flex justify-content-between mb-3'>
            <BForm.Check
              reverse
              label={"Visível no Banner do casino"}
              id={'isVisibleOnBanner'}
              name={'isVisibleOnBanner'}
              type='switch'
              checked={values.isVisibleOnBanner}
              value={values.isAcisVisibleOnBannertive}
              onChange={({ target: { checked } }) => {
                setFieldValue('isVisibleOnBanner', checked)
              }}
              feedback="Qualquer bônus de adesão antigo ficará invisível se este bônus estiver visível no banner"
            />
          </Col>
        }
        {
          [BONUS_TYPE_CONSTANTS.DEPOSIT, BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT, BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT].includes(values.bonusType) &&
          <Col xs={12} className='d-flex justify-content-between mb-3'>
            <BForm.Check
              reverse
              label={"Visível em Promoções"}
              id={'visibleInPromotions'}
              name={'visibleInPromotions'}
              type='switch'
              checked={values.visibleInPromotions}
              value={values.visibleInPromotions}
              onChange={({ target: { checked } }) => {
                setFieldValue('visibleInPromotions', checked)
              }}
            />
          </Col>
        }

      </Row>
      <div className='mt-4 d-flex justify-content-between'>
        <Button
          onClick={() => navigate(-1)}
          className='btn-danger'
        >
          Cancelar
        </Button>

        <Button
          className='ml-2'
          onClick={handleSubmit}
        >
          Próximo
        </Button>
      </div>
    </div>
  )
}

export default GeneralForm
