import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatNumber from "../../../../utils/formatNumber";
import { DashboardLiveContainer } from "../../../Affiliates/Dashboard/style";
import Trigger from "../../../../components/OverlayTrigger";
import {
  CardInfo,
  InfoLabel,
  MainCard,
  StyledCard,
  InfoValue,
} from "../../../Affiliates/Dashboard/components/AffiliateStats/style";
import { MONTHS } from '../../../../utils/constant';
import { AffiliateDashboard } from '../../Dashboard/hooks/useDashboard';
import { getMonthsStartEndDate } from "../../../../utils/getMonthsStartEndDate";
import { getAffiliateLiveCommissions } from "../../../../utils/apiCalls";
import useDebounce from "../../../../hooks/useDebounce";
import { Placeholder } from "react-bootstrap";
import classNames from "classnames";

const ggrClassName = (ggr) => ggr < 0 ? "text-danger" : ggr == 0 ? "" : "text-success"

/**
 * @type {(props: { reportType: 'casino' | 'sports' | 'lives' | 'subAffiliateComissions' | 'subAffiliateLiveComissions' , getSubCategory: () => void}) => void}
 */
const TotalReportsLiveCommission = ({ affiliate, reportType = 'lives' }) => {
  const [dataToBeMap, setDataToBeMap] = useState([]);
  const [liveCommission, setLiveCommission] = useState([]);
  const [loading, setLoading] = useState();

  const reportValue = (liveCommission) => ({
    lives: [
      {
        info: [
          { label: 'Apostas', value: FormatNumber.currency(liveCommission?.betAmount || 0), tooltip: 'Apostas dos usuários que jogaram na sua live' },
          { label: 'Ganhos', value: FormatNumber.currency(liveCommission?.winAmount || 0), tooltip: 'Ganhos dos usuários que jogaram na sua live' },
        ]
      },
      {
        info: [
          { label: 'GGR', value: FormatNumber.currency(liveCommission?.ggr || 0), className: ggrClassName(liveCommission.ggr || 0) },
          { label: 'NGR', value: FormatNumber.currency(liveCommission?.ngr || 0), className: ggrClassName(liveCommission.ngr || 0) },
          { label: 'Comissão', value: FormatNumber.currency(liveCommission?.commission || 0), className: ggrClassName(liveCommission.commission || 0) }
        ]
      }
    ]
  });

  const reportLabel = {
    lives: 'Comissão de Lives',
  }

  const getLiveCommissionByMonth = useDebounce(async (value = '{}') => {
    const startEndDate = JSON.parse(value);

    setLoading(true)

    try {
      const { data } = await getAffiliateLiveCommissions({ affiliateId: affiliate.affiliateId, ...(startEndDate ? startEndDate : {}) });
      const gameReport = data.data?.gameReportsByMonthLive[0]?.gameReport || [];
      setLiveCommission(gameReport);
    } finally {
      setLoading(false);
    }
  }, 500)

  useEffect(() => {
    const date = getMonthsStartEndDate(new Date().getFullYear(), new Date().getMonth())[0];
    getLiveCommissionByMonth(JSON.stringify(date));
  }, [])

  useEffect(() => {
    setDataToBeMap(reportValue(liveCommission)[reportType])
  }, [liveCommission])

  return (
    <DashboardLiveContainer className="p-0">
      <div className="p-3 pb-0 d-flex align-items-center justify-content-between">
        <h5 className='m-0 text-muted fw-bold dashboard-spec-text'>{reportLabel[reportType]}</h5>
        {reportType === 'lives' &&
          <div className='custom-select-wrap'>
            <select className="form-select" aria-label="Default select example" onChange={(e) => { getLiveCommissionByMonth(e.target.value) }}>
              <option selected value={'last-month'}>Último mês</option>
              {(getMonthsStartEndDate(new Date().getFullYear()) ?? []).map(({ month, ...res }, index) => <option key={index} value={JSON.stringify(res)}>{MONTHS[month] || 'N/A'}</option>)}
            </select>
          </div>
        }
      </div>
      <MainCard>
        {dataToBeMap.map((card, index) => (
          <StyledCard key={index} last={index === dataToBeMap.length - 1}>
            {card.info.map((item, idx) => (
              (item.label) ? <CardInfo key={idx}>
                <Trigger message={item.tooltip} placement='top'><InfoLabel className='col-6'>{item.label}</InfoLabel></Trigger>
                <InfoValue className={classNames(item.className, 'col-6 text-right')}>{
                  loading ?
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                    : item.value}</InfoValue>
              </CardInfo> : null
            ))}
          </StyledCard>
        ))}
      </MainCard>
    </DashboardLiveContainer>
  )
}

export default TotalReportsLiveCommission

