export const tableHeaders = [
  { label: 'Affiliate Code', value: 'affiliateCode' },
  { label: 'Email', value: 'email' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'User Name', value: 'username' },
  { label: 'Id', value: 'affiliateId' },
  { label: 'Status', value: 'isActive' },
  { label: 'Payout Percentage', value: 'payoutPercentage' }
]

export const affiliateTableHeaders = [
  { label: 'ID', value: 'affiliateId' },
  { label: 'Usuário', value: 'username' },
  { label: 'Tipo Afiliado', value: 'affiliateType' },
  { label: 'E-mail', value: 'email' },
  { label: 'Nome', value: 'firstName' },
  { label: 'Sobrenome', value: 'lastName' },
  { label: 'Status', value: 'status' },
  { label: 'Ativo', value: 'isActive' },
  { label: 'Visível', value: 'isVisible' },
  { label: 'Sub-afiliado', value: 'parentIdAffiliate' },
  { label: 'Comissão', value: 'commission' },
  { label: 'Cadastrado em:', value: 'createdAt' },
  { label: 'Ação', value: 'action' }
]

export const genderOptions = [
  { id: 1, label: 'Male', value: 'male' },
  { id: 2, label: 'Female', value: 'female' },
  { id: 3, label: 'Others', value: 'others' }
]