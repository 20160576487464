import { Card, Col, Dropdown, Row } from '@themesberg/react-bootstrap'
import React from 'react'
import './style.css'
import Button from '../../../../components/Button'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import UserTemporarySuspensionButton from './UserTemporarySuspensionButton'

const OverviewButton = ({ className, children, hidden, ...props }) => {
  return (!hidden &&
    <div className="col-xl-6 col-md-12 col-sm-6 col-12 mb-3">
      <Button className={classNames('w-100', className)} variant='outline-secondary' {...props}>
        {children}
      </Button>
    </div>
  )
}

const Overview = ({
  basicInfo,
  kycInfo,
  rolloverInfo,
  contactInfo,
  userLimits,
  user,
  setActiveInactiveModal,
  setIsInternalModal,
  setVerifyEmailModal,
  setShowModal,
  setFreeSpinModal,
  setShowManageMoneyModal,
  setShowAddAffiliate,
  addUserAffiliateLoading,
  setShowRemoveAffiliate,
  updateUserAffiliateLoading,
  isHidden,
  setPasswordEmail,
  setUserPassword,
  setEditModal,
  addTag,
  setSendLoyaltyPointsToUserModal,
  handleLogoutUser,
  logoutUserLoading,
}) => {

  return (
    <>
      <Row>
        <Col lg={4} xs={12} className='col-padding'>
          <Card className='card-overview'>
            <h4 className='h4-overview text-center mt-1'>Informações básicas <hr className='h4-hr mt-1' /></h4>
            {/* <div className='div-overview'> */}
            {basicInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h6 className='px-2 overview-leftlabel'>{label}</h6>
                    <span className={`${subValue ?? ""} text-right px-2`}>{value || 'N/A'}</span>
                  </div>
                )
            })}
          </Card>
        </Col>
        <Col lg={4} xs={12} className='col-padding'>
          <Card className='card-overview'>
            <h4 className='h4-overview text-center mt-1'>Ações da conta <hr className='h4-hr mt-1' /></h4>
            <div className='d-flex flex-wrap px-3 row'>
              <OverviewButton
                variant='outline-success'
                hidden={isHidden({ module: { key: 'Users', value: 'AB' } })}
                onClick={() => setShowManageMoneyModal(true)}
              >
                Gerenciar dinheiro
              </OverviewButton>

              <OverviewButton
                hidden={isHidden({ module: { key: 'Users', value: 'U' } })}
                onClick={() => setEditModal(true)}
              >
                Editar usuário
              </OverviewButton>

              <OverviewButton
                variant='outline-success'
                hidden={isHidden({ module: { key: 'Users', value: 'UP' } }) || user?.isEmailVerified}
                onClick={() => setVerifyEmailModal(true)}
              >
                Verificar E-mail
              </OverviewButton>

              <UserTemporarySuspensionButton {...{ user }} />

              <OverviewButton
                variant={user?.isActive ? 'outline-danger' : 'outline-success'}
                hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                onClick={() => setActiveInactiveModal(true)}
              >
                {user && user?.isActive ? 'Desativar usuário' : 'Ativar usuário'}
              </OverviewButton>

              <OverviewButton
                variant={user?.isActive ? 'outline-danger' : 'outline-success'}
                hidden={isHidden({ module: { key: 'Users', value: 'T' } })}
                onClick={handleLogoutUser}
                loading={logoutUserLoading}
              >
                Deslogar usuário
              </OverviewButton>

              {!isHidden({ module: { key: 'Users', value: 'UP' } }) &&
                <Dropdown className='col-xl-6 col-md-12 col-sm-6 col-12'>
                  <Dropdown.Toggle
                    id='dropdown-autoclose-outside'
                    variant='outline-success'
                    className='w-100'
                  >
                    Mais opções
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='user-dropdown user wc-fit'>
                    <Dropdown.Item onClick={() => setUserPassword(true)}>
                      Resetar senha
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setPasswordEmail(true)}>
                      Enviar e-mail de recuperação de senha
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setSendLoyaltyPointsToUserModal(true)}>
                      Adicionar pontos de fidelidade
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => addTag()}>
                      Gerenciar etiqueta
                    </Dropdown.Item>
                    {!isHidden({ module: { key: 'Users', value: 'U' } }) || user?.tags?.includes('Internal') ? <Dropdown.Item onClick={() => setIsInternalModal()}>
                      Interno
                    </Dropdown.Item> : null}
                  </Dropdown.Menu>
                </Dropdown>}
            </div>
          </Card>
        </Col>
        <Col lg={4} xs={12} className=''>
          <Card className='card-overview'>
            <h4 className='h4-overview text-center mt-1'>Outras informações <hr className='h4-hr mt-1' /></h4>
            <h5 className='overview-subhead mt-1'>Contato</h5>
            {contactInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h6 className='px-2 overview-leftlabel'>{label}</h6>
                    <span className={`${subValue} px-2`}>{value || 'N/A'}</span>
                  </div>
                )
            })}

            {/* <div className='div-overview'>	 */}
            <h5 className='overview-subhead mt-2'>Afiliado </h5>
            <div className='d-flex justify-content-between m-1'>
              <h6 className='px-2 overview-leftlabel'>Token Afiliado</h6>
              <span className='px-2'>{user?.affiliateId ? <Link to={`/admin/affiliate-details/${user?.affiliateId}`} target='_blank'>{user?.affiliate?.username}</Link> : "N/A"}</span>
            </div>
            <div className='d-flex justify-content-between m-1'>
              <h6 className='px-2 overview-leftlabel'> Status do Afiliado</h6>
              {user?.affiliateId
                ? (
                  <span className='text-success px-2'>Vinculado</span>
                )
                : (
                  <span className='text-danger px-2'>Não Vinculado</span>)}
            </div>
            {/* </div> */}
            <h5 className='overview-subhead mt-2'>Documentos KYC </h5>
            {kycInfo?.map(({ label, value, subValue }) => {
              return (user?.kycMethod !== 1 && label === 'Applicant Id')
                ? ''
                : (label === 'Reason' && value ? true : label !== 'Reason') && (
                  <div key={label} className='d-flex justify-content-between m-1'>
                    <h6 className='px-2 overview-leftlabel'>{label}</h6>
                    <span className={`${subValue} px-2`}>{value || 'N/A'}</span>
                  </div>
                )
            })}
            <h5 className='overview-subhead mt-2'>Rollover</h5>
            {rolloverInfo?.map(({ label, value, subValue }) => {
              return (
                <div key={label} className='d-flex justify-content-between m-1'>
                  <h6 className='px-2 overview-leftlabel'>{label}</h6>
                  <span className={`${subValue} px-2`}>{value || 'N/A'}</span>
                </div>
              )
            })}
          </Card>
        </Col>
      </Row>
      {/* <Row>
        {user?.affiliate &&
          <Col xs={5}>
            <Affiliate userData={user} />
          </Col>}
      </Row> */}
    </>
  )
}

export default Overview
