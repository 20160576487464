export const checkLabels = (bonusType) => {
  if (['freespins', 'cashfreespins', 'balance', 'deposit', 'promotion'].includes(bonusType)) {
    return [
      { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' },
      { label: 'Visible In Promotions', value: 'visibleInPromotions', message: 'If true visible in promotion else not' }
    ]
  } else if (bonusType === BONUS_TYPE_CONSTANTS.JOINING) {
    return [
      { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' },
      { label: 'Visible On banner', value: 'other.isVisibleOnBanner', message: 'If True Status is Active else In-Active' }
    ]
  }
  else {
    return [
      { label: 'Active', value: 'isActive', message: 'If True Status is Active else In-Active' }
    ]
  }
}

export const daysLabels = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

export const convertAmountOptions = [
  { label: 'Max Bonus Claimed', value: 'maxBonusThreshold' },
  { label: 'Valor minímo de depósito', value: 'minDeposit' },
  { label: 'Valor máximo de bônus recebido', value: 'maxWinAmount' },
  { label: 'Zero Out Threshold', value: 'zeroOutThreshold' },
  { label: 'Min Wallet Balance', value: 'minBalance' },
  { label: 'Max Allowed Balance', value: 'minBalanceCash' },
  { label: 'Joining Bonus', value: 'joiningAmount' },
  { label: 'One Coin Equaivalent To', value: 'coinEquivalentTo' }
]

export const bonusType = [
  { label: 'CASINO DEPOSIT', value: 'casino_deposit', id: 0 },
  // { label: 'SPORTS DEPOSIT', value: 'sports_deposit', id: 1 },
  // { label: 'BALANCE', value: 'balance', id: 1 },
  // { label: 'FREESPINS', value: 'freespins', id: 2 },
  // { label: 'CASH FREESPINS', value: 'cashfreespins', id: 3 },
  { label: 'DEPOSIT', value: 'deposit', id: 4 },
  // { label: 'WAGERING(CASHBACK)', value: 'wagering', id: 5 },
  // { label: 'PROMOTION', value: 'promotion', id: 6 },
  { label: 'JOINING', value: 'joining', id: 7 },
  { label: 'LOYALTY', value: 'loyalty', id: 8 }
]

export const wageringRequirementType = [
  { label: 'BONUS', value: 'bonus', id: 1 },
  { label: 'BONUS+DEPOSIT', value: 'bonusdeposit', id: 2 }
]

export const tabLabels = [
  'general', 'currency', 'payment', 'wageringContribution', 'games', 'loyalty'
]

export const BONUS_TYPE_CONSTANTS = {
  BALANCE: 'balance',
  FREESPINS: 'freespins',
  DEPOSIT: 'deposit',
  JOINING: 'joining',
  WAGERING: 'wagering',
  PROMOTION: 'promotion',
  REFERRAL: 'referral',
  LOYALTY: 'loyalty',
  CASINO_DEPOSIT: 'casino_deposit',
  SPORTS_DEPOSIT: 'sports_deposit'
}