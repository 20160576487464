import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "@themesberg/react-bootstrap";
import PaginationComponent from "../../../../components/Pagination";
import Trigger from "../../../../components/OverlayTrigger";

import { formatDateYMD, getDateByYear, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { ACTIVE_LABEL, ACTIVE_VALUE, STATUS_LABEL } from "../../../../utils/constant";
import FormatDate from "../../../../utils/formatDate";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { tableUsersAccessHeaders } from "../userConstant";
import useDebounce from "../../../../hooks/useDebounce";
import { resolveErrors } from "../../../../utils/errorTypes";
import { getUsersAccess } from "../../../../utils/apiCalls";
import { toast } from "../../../../components/Toast";
import { Loading } from "../../../../components/Preloader";
import IpAddressInput from "../../../../components/IpAddressInput";
import { Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Button";
import { AdminsRoutes } from "../../../../routes";
import DateRangePicker from "../../../../components/DateRangePicker";

export default () => {
  const [userData, setUserData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [userId, setUserId] = useState(searchParams.get("userId") || "");
  const [loginIp, setSearchByIp] = useState(searchParams.get("loginIp") ? decodeURIComponent(searchParams.get("loginIp")) : "");
  const navigate = useNavigate();

  const initialRangeDate = (reset) => [{
    startDate: !reset && searchParams.get("startDate") ? new Date(searchParams.get("startDate")) : getDateByYear(2023),
    endDate: !reset && searchParams.get("endDate") ? new Date(searchParams.get("endDate")) : new Date(),
    key: 'selection'
  }]
  const [dateRange, setDateRange] = useState(initialRangeDate)

  const loadUsersAccess = useDebounce(async ({ loading, initialLoading, page, userId, loginIp }) => {
    if (loading) setLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await getUsersAccess({
        startDate: formatDateYMD(dateRange.map(a => a.startDate)),
        endDate: formatDateYMD(dateRange.map(a => a.endDate)),
        userId,
        loginIp,
        pageNo: page || 1,
        limit: 15,
        loginIpDistinct: true
      })
      setUserData(res.data.data);
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter acessos de usuários"), "error");
    } finally {
      setLoading(false)
      setInitialLoading(false)
    }
  }, 400)

  const resetFilter = () => {
    navigate(AdminsRoutes.UsersAccess, { replace: true })
    setUserId('');
    setSearchByIp('');
    setDateRange(initialRangeDate(true))
  }

  useEffect(() => {
    loadUsersAccess({ userId, loginIp, loading: true });
  }, [userId, loginIp, dateRange])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Acessos de usuários</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col md={3} xs={4}>
            <Form.Control
              type="number"
              placeholder="User id"
              size="sm"
              value={userId}
              onChange={(event) => setUserId(event.target.value)}
            />
          </Col>

          <Col md={3} xs={7}>
            <IpAddressInput
              type="text"
              placeholder="Pesquisar por IP"
              size="sm"
              value={loginIp}
              onChange={(event) =>
                setSearchByIp(event.target.value)
              }
            />
          </Col>
          <Col md={3} xs={11}>
            <DateRangePicker
              state={dateRange} setState={setDateRange}
              size='sm'
            />
          </Col>

          <Col md={3} xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  size="sm"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">

        <div className='position-relative h-100'>
          <Table striped responsive hover size="sm" className="text-center mt-3">
            <thead className="thead-light">
              <tr>
                {tableUsersAccessHeaders.map((h, idx) => (
                  <th key={idx}> {h.label}{" "} </th>
                ))}
              </tr>
            </thead>

            <tbody >
              {(!initialLoading && !loading) &&
                userData?.result?.map(
                  ({
                    userId,
                    user,
                    userAccessId,
                    loginIp,
                    startedAt,
                    finishedAt,
                    timeAtUnix,
                  }) => {
                    return (
                      <tr key={userId}>
                        <td >{userAccessId}</td>
                        <td className="text-left"><Link className="text-link" to={`/admin/users/details/${userId}`}>{user.username}</Link></td>
                        <td className="text-left">{loginIp}</td>
                        <td>{FormatDate.default(startedAt)}</td>
                        <td>{FormatDate.default(finishedAt)}</td>
                        <td>{FormatDate.unixToDuration(timeAtUnix)}</td>
                      </tr>
                    );
                  }
                )}

              {userData?.result?.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-danger text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {(initialLoading || loading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userData?.total || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.total !== 0 && !initialLoading && (
              <PaginationComponent
                className=""
                page={userData?.total < page ? setPage(1) : page}
                totalPages={userData?.totalPages}
                setPage={(page) => {
                  setPage(page);
                  loadUsersAccess({ loading: true, page, userId, loginIp });
                }}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};
