import { MONTHS } from "./constant"


export function getMonthsStartEndDate(year, month) {
  const numDays = (y, m) => new Date(y, m, 0).getDate()
  return Object.keys(MONTHS).map((month, index) => {
    let dateCount = numDays(year, index + 1)
    return {
      month,
      monthInt: index + 1,
      startDate: `${year}-${String(index + 1).padStart(2, '0')}-01`,
      endDate: `${year}-${String(index + 1).padStart(2, '0')}-${String(dateCount).padStart(2, '0')}`,
    }
  }).filter(({ monthInt }) => ((month + 1) && (month + 1) == monthInt) || !month)
}