import React from 'react'
import {
  Button,
  Form,
  Row,
  Col,
  Table
} from '@themesberg/react-bootstrap'
import { motion } from 'framer-motion/dist/framer-motion'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckSquare, faEdit, faEye, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import useCmsListing from './hooks/useCmsListing'
import Trigger from '../../../components/OverlayTrigger'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { AdminsRoutes } from '../../../routes'
import useCheckPermission from '../../../utils/checkPermission'
import ConfirmationModal from '../../../components/ConfirmationModal'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
// import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded'
// import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'
// import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
// import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import ActionMenu from './ActionMenu'
import { ACTIVE_LABEL, ACTIVE_VALUE } from '../../../utils/constant'
import { Link } from 'react-router-dom'
export default () => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    setSearch,
    search,
    setSelectedClient,
    navigate,
    cmsData,
    totalPages,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    loading,
    handleStatusShow,
    statusShow,
    setStatusShow,
    handleYes,
    status,
    active,
    setActive,
    name,
    handleDeleteCms
  } = useCmsListing();
  const { isHidden } = useCheckPermission();

  //   const [anchorEl, setAnchorEl] = React.useState(null)
  //   const open = Boolean(anchorEl)
  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget)
  //   }
  //   const handleClose = () => {
  //     setAnchorEl(null)
  //   }
  return (
    <>
      <div className='section-header-wrap mt-n3 mx-n3'>

        <Row>
          <Col lg={8} xs={7}>
            <h2 className='title-text'>CMS</h2>
          </Col>
          <Col lg={4} xs={5}>
            <div className='d-flex justify-content-end'>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant='btn btn-primary w-100'
                  onClick={() => navigate(AdminsRoutes.CreateCMS)}
                  hidden={isHidden({ module: { key: 'CMS', value: 'C' } })}
                  style={{ maxWidth: '140px' }}
                >
                  <AddCircleOutlineRoundedIcon className='me-2' />
                  <span>Criar</span>
                </Button>
              </motion.div>
            </div>
          </Col>
        </Row>
      </div>
      <div className='FormsBg'>
        <Row>
          {/* <Col xs='3'>
            <ClientFilter
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />
            </Col>  */}
          {/* <Col xs={2}>
            {selectedClient && (
              <PortalFilter
                setSelectedPortal={setSelectedPortal}
                selectedPortal={selectedPortal}
                selectedClient={selectedClient}
              />
            )}
             </Col> */}

          <Col xs='3'>
            <Form.Control
              type='search'
              style={{ marginRight: '10px' }}
              value={search}
              placeholder='Título de pesquisa, slug'
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

          </Col>
          <Col xs='3'>

            <Form.Select
              style={{ marginRight: '10px' }}
              value={active}
              onChange={(event) =>
                setActive(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            >
              <option key='' value=''>Status</option>
              <option key='true' value>Ativos</option>
              <option key='false' value={false}>Inativo</option>
            </Form.Select>
          </Col>
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>

        <Table striped responsive hover size='sm' className='text-center mt-3'>
          <thead className='thead-light'>
            <tr>
              {['Id', 'Título', 'Slug', 'Portal', 'Status', 'Action'].map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && Boolean(cmsData) &&
              cmsData?.rows?.map(
                ({ cmsPageId, title, slug, isActive, tenant, tenantId }) => {
                  return (
                    <tr key={cmsPageId}>
                      <td>{cmsPageId}</td>

                      <td className='text-left'>
                        <Trigger message={title?.EN}>
                          <Link
                            to={`/admin/cms/details/${cmsPageId}`}
                            className='text-link d-inline-block text-truncate cursor-pointer'
                          >
                            {title?.EN}
                          </Link>
                        </Trigger>
                      </td>

                      <td className='text-left'>{slug}</td>

                      <td>
                        <Trigger message={tenant?.name ? `${tenant?.name} (${tenant?.domain})` : 'All'}>
                          <span
                            className='d-inline-block text-truncate'
                          >
                            {tenant?.name ? `${tenant?.name}(${tenant?.domain})` : 'All'}
                          </span>
                        </Trigger>
                      </td>
                      <td><span className="w-100" data-badge={ACTIVE_VALUE[isActive]}>{ACTIVE_LABEL[isActive]}</span></td>

                      <td>
                        <ActionMenu
                          cmsPageId={cmsPageId}
                          isHidden={isHidden}
                          handleStatusShow={handleStatusShow}
                          isActive={isActive}
                          tenantId={tenantId}
                          title={title}
                          navigate={navigate}
                          handleDeleteCms={handleDeleteCms}
                        />
                      </td>
                    </tr>
                  )
                }
              )}

            {cmsData?.count === 0 && !loading && (
              <tr>
                <td colSpan={6} className='text-danger text-center'>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {cmsData?.count !== 0 && !loading && (
          <PaginationComponent
            page={cmsData?.count < page ? setPage(1) : page}
            totalPages={totalPages}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        )}
      </div>

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={name}
      />
    </>
  )
}
