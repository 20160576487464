import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "@themesberg/react-bootstrap";
import PaginationComponent from "../../../components/Pagination";
import Trigger from "../../../components/OverlayTrigger";

import { getDateDaysAgo } from '../../../utils/dateFormatter'
import { ACTIVE_LABEL, ACTIVE_VALUE, STATUS_LABEL } from "../../../utils/constant";
import FormatDate from "../../../utils/formatDate";
import { Link } from "react-router-dom";
import useDebounce from "../../../hooks/useDebounce";
import { resolveErrors } from "../../../utils/errorTypes";
import { getUserWallets } from "../../../utils/apiCalls";
import { toast } from "../../../components/Toast";
import { Loading } from "../../../components/Preloader";
import { RiFileExcel2Fill } from "react-icons/ri";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import { downloadBlob } from "../../../utils/downloadBlob";
import FormatNumber from "../../../utils/formatNumber";
import classNames from "classnames";

const tableUsersAccessHeaders = [
  { label: 'ID do usuário', value: 'userId' },
  { label: 'Nome de Usuário', value: 'username' },
  { label: 'Nome', value: 'name' },
  { label: 'E-mail', value: 'email' },
  { label: 'CPF', value: 'cpf' },
  { label: 'Pode Sacar', value: 'allowWithdraw' },
  { label: 'Moeda', value: 'currencyCode' },
  { label: 'Saldo em Carteira', value: 'amount' },
  { label: 'Saldo de Bônus', value: 'nonCashAmount' },
]

export default () => {
  const [userData, setUserData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [page, setPage] = useState(1);

  const loadUserWallets = useDebounce(async ({ loading, initialLoading, page, exportToExcel }) => {
    if (loading) setLoading(true);
    if (exportToExcel) setExportLoading(true);
    if (initialLoading) setInitialLoading(true);
    try {
      const res = await getUserWallets({
        pageNo: page || 1,
        limit: 15,
        ...(exportToExcel ? { exportToExcel: true } : {})
      })
      if (exportToExcel) {
        downloadBlob(`user_wallets_report_${new Date().toISOString()}`, res.data);
      } else {
        setUserData(res.data.data);
      }
    } catch (err) {
      toast(resolveErrors(err?.response?.data?.errors, "Error ao obter carteiras de usuários"), "error");
    } finally {
      setLoading(false);
      setExportLoading(false);
      setInitialLoading(false);
    }
  }, 400)

  const exportToExcel = () => {
    loadUserWallets({ exportToExcel: true })
  }

  const resetFilter = () => {

  }

  useEffect(() => {
    loadUserWallets({ loading: true });
  }, [])

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Carteira dos usuários</h2>
          </Col>
        </Row>
      </div>
      <div className="FormsBg">
        <Row>
          <Col md={10} xs={10}></Col>
          {/* <Col md={6} xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Reset Filters">
                <Button
                  size="sm"
                  onClick={resetFilter}
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col> */}
          <Col md={2} xs={2}>
            <Button className='btn-success w-100' onClick={exportToExcel} loading={exportLoading}> <RiFileExcel2Fill /> Exportar </Button>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">

        <div className='position-relative h-100'>
          <Table striped responsive hover size="sm" className="text-center mt-3">
            <thead className="thead-light">
              <tr>
                {tableUsersAccessHeaders.map((h, idx) => (
                  <th key={idx}> {h.label}{" "} </th>
                ))}
              </tr>
            </thead>

            <tbody >
              {(!initialLoading && !loading) &&
                userData?.result?.map(
                  ({
                    userId,
                    username,
                    userWallet,
                    firstName,
                    lastName,
                    cpf,
                    email,
                    currencyCode,
                    allowWithdraw
                  }) => {
                    return (
                      <tr key={userId}>
                        <td >{userId}</td>
                        <td className="text-left"><Link className="text-link" to={`/admin/users/details/${userId}`}>{username}</Link></td>
                        <td className="text-left">{classNames(firstName, lastName)}</td>
                        <td className="text-left">{email}</td>
                        <td className="text-left">{cpf}</td>
                        <td>{allowWithdraw ? "Sim" : "Não"}</td>
                        <td className="text-center">{currencyCode}</td>
                        <td className={classNames("text-right", FormatNumber.getClassNameColor(userWallet.amount))}>{FormatNumber.currency(userWallet.amount || 0)}</td>
                        <td className={classNames("text-right", FormatNumber.getClassNameColor(userWallet.nonCashAmount))}>{FormatNumber.currency(userWallet.nonCashAmount || 0)}</td>
                      </tr>
                    );
                  }
                )}

              {userData?.result?.length === 0 && (
                <tr>
                  <td colSpan={13} className="text-danger text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {(initialLoading || loading) ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userData?.total || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userData?.total !== 0 && !initialLoading && (
              <PaginationComponent
                className=""
                page={userData?.total < page ? setPage(1) : page}
                totalPages={userData?.totalPages}
                setPage={(page) => {
                  setPage(page);
                  loadUserWallets({ loading: true, page });
                }}
              />
            )}
          </div>
        </div>

      </div>
    </>
  );
};
