import {
  parseISO,
  format,
  differenceInDays,
  startOfDay,
  endOfDay,
  intervalToDuration,
  formatDuration,
  getDay
} from 'date-fns';

/**A Helper to format date */
export class FormatDate {

  static default(/** @type { string }*/date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(date),
      "dd-MM-yyyy' às ' HH:mm'h'",
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static toDateHour(/** @type { string }*/date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(date),
      "dd-MM-yyyy' às ' HH:mm'h'",
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static toDateOnly(/** @type { string }*/date, separator = "-") {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return format(
      parseISO(new Date(date).toISOString()),
      `dd${separator}MM${separator}yyyy`,
      { timeZone: 'America/Sao_Paulo' }
    );
  }

  static differenceInDays(/** @type { string | Date }*/startDate, /** @type { string | Date }*/ endDate = new Date()) {
    if (startDate == null || typeof startDate == 'undefined' || !startDate) return 'N/A'
    return differenceInDays(parseISO(new Date(endDate).toISOString()), parseISO(new Date(startDate).toISOString()));
  }

  static toStartDate(/** @type { string | Date }*/startDate,) {
    return startOfDay(new Date(startDate))
  }

  static toEndDate(/** @type { string | Date }*/endDate,) {
    return endOfDay(new Date(endDate))
  }

  /** `YYYY-MM-DD` --> `DD-MM-YYYY` */
  static toDayMonthYear(/** @type { string }*/date, separator = "-") {
    return date.split(separator).reverse().join(separator);
  }

  static unixToDuration(/** @type { string }*/timeUnix) {
    if (timeUnix == null || typeof timeUnix == 'undefined' || !timeUnix) return 'N/A';
    const intialDuration = { hours: 0, minutes: 0, seconds: 0 }
    const duration = intervalToDuration({ start: 0, end: timeUnix * 1000 });
    const zeroPad = (num) => String(num).padStart(2, "0");
    const formatted = formatDuration({ ...intialDuration, ...duration }, {
      // format: ["minutes", "seconds"],
      format: ["hours", "minutes", "seconds"],
      zero: true,
      delimiter: ":",
      locale: {
        formatDistance: (_token, count) => {
          console.log({ count })
          return zeroPad(count)
        }
      }
    });
    return formatted;
  }

  static toDateOfBirth(date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return this.toDayMonthYear(this.getDateOnlyFromISO(date));
  }

  static getDateOnlyFromISO(date) {
    if (date == null || typeof date == 'undefined' || !date) return 'N/A'
    return date.split("T", 1)[0];
  }
}

export default FormatDate
