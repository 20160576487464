import {
  Tabs,
  Tab,
  Row,
  Col,
  Form as BForm
} from '@themesberg/react-bootstrap'
import { Formik } from 'formik'
import React, { useState } from 'react'
import GeneralForm from '../CreateBonus/general'
import useCreateBonus from '../hooks/useCreateBonus'
import CurrenciesForm from '../CreateBonus/currencies'
import { updateBonusStart } from '../../../../../store/redux-slices/bonus'
import { formatDateYMD } from '../../../../../utils/dateFormatter'
import useEditBonus from '../hooks/useEditBonus'
import Preloader from '../../../../../components/Preloader'
import { BONUS_TYPE_CONSTANTS, tabLabels } from '../CreateBonus/constants'
import { bonusSchema } from '../CreateBonus/schema'
import PaymentMethod from '../CreateBonus/PaymentMethod'
import WageringContributionForm from '../CreateBonus/wageringContribution'
import Games from '../CreateBonus/Games'
import { toast } from '../../../../../components/Toast'
import LoyaltySettings from '../CreateBonus/LoyaltySettings'
import Languages from '../CreateBonus/Languages'
import BonusCountry from '../CreateBonus/BonusCountry'
import { safeStringify } from '../../../../../utils/helpers'

const EditBonus = () => {
  const {
    selectedTab,
    setSelectedTab,
    state,
    dispatch,
    bonusDetail,
    bonusId,
    portalOptions,
    loading,
    navigate,
    setState,
    countryData,
    setCountryData
  } = useEditBonus()

  const {
    handleImagePreview,
    preview,
    setActiveTab,
    setCurr,
    enableSubmit,
    setEnableSubmit,
    wageringTemplateList,
    wageringTemplateDetail,
    clientsFilterData,
    setSelectedClient,
    getBalanceBonusesOptions,
    balanceBonusOptions,
    pageNo,
    setPageNo,
    limit,
    setLimit,
    totalPages,
    gameIds,
    setGameIds,
    search,
    setSearch,
    casinoGamesData,
    selectedProvider,
    setSelectedProvider,
    selectedClient,
    gamesLimit,
    gamesPageNo,
    setGamesLimit,
    setGamesPageNo,
    appliedBonusOptions,
    languages,
    data,
    setData,
    set
  } = useCreateBonus(bonusDetail)
  const [loyaltyCount, setLoyaltyCount] = useState(1)

  return (
    <>

      <div className='section-header-wrap mt-n3 mx-n3'>
        <Col>
          <h3>Edit Bonus: {bonusDetail?.promotionTitle?.EN} </h3>
        </Col>
      </div>


      {!loading && set
        ? (
          <Formik
            enableReinitialize
            initialValues={{
              promotionTitle: bonusDetail?.promotionTitle?.EN || '',
              bonusType: bonusDetail?.bonusType || '',
              validFrom: bonusDetail?.validFrom || state.startDate,
              validTo: bonusDetail?.validTo || state.endDate,
              termCondition: bonusDetail?.termCondition?.EN || '',
              quantity: bonusDetail?.quantity || 0,
              daysToClear: bonusDetail?.daysToClear || 0,
              currency: bonusDetail?.currency || {},
              maxBonusThreshold: '',
              status: bonusDetail?.status || '',
              minDeposit: '',
              wageringRequirementType: bonusDetail?.wageringRequirementType || '',
              maxWinAmount: '',
              isWinCashAmt: '',
              isBonusWagering: '',
              depositBonusPercent: bonusDetail?.depositBonusPercent,
              // tenantIds: portalOptions.filter(
              //   (tenant) => tenant.value === bonusDetail?.tenantId
              // ),
              // adminId: bonusDetail?.adminId,
              validOnDays: bonusDetail?.validOnDays,
              bonusImage: null,
              isActive: bonusDetail?.isActive || false,
              showBonusValidity: bonusDetail?.other?.showBonusValidity,
              visibleInPromotions: bonusDetail?.visibleInPromotions || false,
              isSticky: bonusDetail?.isSticky || false,
              wageringMultiplier: bonusDetail?.wageringMultiplier || 1,
              paymentMethods: { [bonusDetail?.tenantId]: bonusDetail?.paymentMethods } || { [bonusDetail?.tenantId]: [] },
              wageringTemplateId: bonusDetail?.wageringTemplateId || '',
              appliedBonusId: '',
              appliedBonusVal: bonusDetail?.appliedBonusId,
              description: bonusDetail?.description?.EN || '',
              loyaltyLevel: bonusDetail?.other?.loyaltyLevel || [{}],
              minBalance: '',
              timePeriod: bonusDetail?.other?.timePeriod || '1',
              other: bonusDetail?.other,
              betLevel: bonusDetail?.other?.betLevel || 1,
              bonusCode: bonusDetail?.bonusCode || '',
              isVisibleOnBanner: bonusDetail?.other?.isVisibleOnBanner
            }}
            validationSchema={
              bonusSchema(bonusDetail?.currency, { bonusDetail })[tabLabels.findIndex((val) => val === selectedTab)]
            }
            onSubmit={(formValues) => {
              console.log("formValues: " + formValues)
              Object.keys(formValues).forEach((key) => {
                if (formValues[key] === null || formValues[key] === '') {
                  delete formValues[key]
                }
              })

              const checkOther = (data) => {
                const other = typeof data === 'object' ? safeStringify(data) : data
                if (safeStringify(bonusDetail?.other) === other) {
                  return {}
                } else {
                  return data
                }
              }
              if (formValues.bonusType === BONUS_TYPE_CONSTANTS.JOINING) {

                if (selectedTab === 'general' && bonusDetail?.claimedCount) {
                  // const myShowBonusValidity = formValues?.showBonusValidity
                  delete formValues?.showBonusValidity
                  delete formValues?.loyaltyLevel
                  delete formValues?.paymentMethods
                  formValues?.appliedBonusId && delete formValues?.appliedBonusId
                  // formValues.gameIds = gameIds
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  // gameIds && gameIds?.length
                  // dispatch(
                  //   updateBonusStart({
                  //     data: {
                  //       ...formValues,
                  //       bonusId,
                  //       validFrom: formatDateYMD(state.map((a) => a.startDate)),
                  //       validTo: formatDateYMD(state.map((a) => a.endDate)),
                  //       // tenantIds: formValues.tenantIds.map((id) => id.value),
                  //       // other: checkOther({ betLevel: formValues?.betLevel, countries: countryData, showBonusValidity: myShowBonusValidity })
                  //     },
                  //     navigate
                  //   })
                  // )
                  // : toast('Select At least One Game.', 'error')
                  setSelectedTab('currency')
                }
                else if (selectedTab === 'currency') {
                  // const myShowBonusValidity = formValues?.showBonusValidity
                  delete formValues?.showBonusValidity
                  delete formValues?.loyaltyLevel
                  delete formValues?.paymentMethods
                  formValues?.appliedBonusId && delete formValues?.appliedBonusId
                  // formValues.gameIds = gameIds
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  // gameIds && gameIds?.length
                  formValues.other = null
                  dispatch(
                    updateBonusStart({
                      data: {
                        ...formValues,
                        bonusId,
                        validFrom: formatDateYMD(state.map((a) => a.startDate)),
                        validTo: formatDateYMD(state.map((a) => a.endDate)),
                        // tenantIds: formValues.tenantIds.map((id) => id.value),
                        // other: checkOther({ betLevel: formValues?.betLevel, countries: countryData, showBonusValidity: myShowBonusValidity })
                      },
                      navigate
                    })
                  )
                  // : toast('Select At least One Game.', 'error')
                } else {
                  setSelectedTab(bonusDetail?.claimedCount ? 'general' :
                    selectedTab === 'general' ? "currency" : selectedTab === 'currency' && (formValues?.isSticky === 'true' || formValues?.isSticky))

                }
              }
              else if ([BONUS_TYPE_CONSTANTS.DEPOSIT, BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT, BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT].includes(formValues.bonusType)) {
                if (selectedTab === 'countries') {
                  const myShowBonusValidity = formValues?.showBonusValidity
                  delete formValues?.showBonusValidity
                  const timePeriod = formValues?.timePeriod
                  delete formValues?.timePeriod
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods?.[bonusDetail?.tenantId]) : {}
                  dispatch(
                    updateBonusStart({
                      data: {
                        ...formValues,
                        bonusId,
                        validFrom: formatDateYMD(state.map((a) => a.startDate)),
                        validTo: formatDateYMD(state.map((a) => a.endDate)),
                        other: checkOther(safeStringify({
                          countries: countryData,
                          ...formValues.other
                        })),
                        loyaltyLevel: ''
                      },
                      navigate
                    })
                  )
                } else {
                  if (selectedTab === 'countries') {
                    const myShowBonusValidity = formValues?.showBonusValidity
                    delete formValues?.showBonusValidity
                    const timePeriod = formValues?.timePeriod
                    delete formValues?.timePeriod
                    formValues?.appliedBonusId && delete formValues?.appliedBonusId
                    formValues.promotionTitle = safeStringify(data?.promoTitle)
                    formValues.description = safeStringify(data?.desc)
                    formValues.termCondition = safeStringify(data?.terms)
                    formValues.paymentMethods = formValues?.paymentMethods ? safeStringify(formValues?.paymentMethods) : {}
                    dispatch(
                      updateBonusStart({
                        data: {
                          ...formValues,
                          validFrom: formatDateYMD(state.map((a) => a.startDate)),
                          validTo: formatDateYMD(state.map((a) => a.endDate)),
                          // tenantIds: formValues.tenantIds.map((id) => id.value),
                          other: checkOther(safeStringify(formValues?.bonusType === 'deposit'
                            ? { countries: countryData, showBonusValidity: myShowBonusValidity }
                            : { loyaltyLevel: formValues?.loyaltyLevel, timePeriod, countries: countryData, showBonusValidity: myShowBonusValidity })),
                          loyaltyLevel: ''
                        },
                        navigate
                      })
                    )
                  } else {
                    let newSelectedTab;
                    if (selectedTab === 'currency') {
                      newSelectedTab = 'countries';
                    }
                    else if (selectedTab === 'general') {
                      newSelectedTab = 'currency';
                    }

                    setSelectedTab(newSelectedTab);
                  }
                }

              } else if (formValues?.bonusType === BONUS_TYPE_CONSTANTS.LOYALTY) {
                if (selectedTab === 'general') {
                  delete formValues?.showBonusValidity
                  delete formValues?.loyaltyLevel
                  delete formValues?.paymentMethods
                  formValues?.appliedBonusId && delete formValues?.appliedBonusId
                  // formValues.gameIds = gameIds
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  setSelectedTab('currency')
                } else if (selectedTab === 'currency') {

                  delete formValues?.showBonusValidity
                  delete formValues?.loyaltyLevel
                  delete formValues?.paymentMethods
                  formValues?.appliedBonusId && delete formValues?.appliedBonusId
                  // formValues.gameIds = gameIds
                  formValues.promotionTitle = safeStringify(data?.promoTitle)
                  formValues.description = safeStringify(data?.desc)
                  formValues.termCondition = safeStringify(data?.terms)
                  // gameIds && gameIds?.length
                  formValues.other = null
                  dispatch(
                    updateBonusStart({
                      data: {
                        ...formValues,
                        bonusId,
                        validFrom: formatDateYMD(state.map((a) => a.startDate)),
                        validTo: formatDateYMD(state.map((a) => a.endDate)),
                        // tenantIds: formValues.tenantIds.map((id) => id.value),
                        // other: checkOther({ betLevel: formValues?.betLevel, countries: countryData, showBonusValidity: myShowBonusValidity })
                      },
                      navigate
                    })
                  )
                }
              }
            }}
          >
            {({
              touched,
              errors,
              values,
              setSubmitting,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue
            }) => (
              <BForm>
                <Tabs
                  key={selectedTab}
                  activeKey={selectedTab}
                  className='nav-light'
                  onSelect={(k) => setSelectedTab(k)}
                  mountOnEnter
                  unmountOnExit
                >
                  <Tab disabled eventKey='general' title='General'>
                    <div className='mt-5'>
                      <GeneralForm
                        state={state}
                        setState={setState}
                        isEdit
                        setSelectedTab={setSelectedTab}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setSubmitting={setSubmitting}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        bonusDetail={bonusDetail}
                        handleImagePreview={handleImagePreview}
                        preview={preview}
                        navigate={navigate}
                        clientsFilterData={clientsFilterData}
                        setSelectedClient={setSelectedClient}
                        portalOptions={portalOptions}
                        getBalanceBonusesOptions={getBalanceBonusesOptions}
                        balanceBonusOptions={balanceBonusOptions}
                        wageringTemplateList={wageringTemplateList}
                        setEnableSubmit={setEnableSubmit}
                        appliedBonusOptions={appliedBonusOptions}
                        data={data}
                        setData={setData}
                        languages={languages}
                        setCountryData={setCountryData}
                      />
                    </div>
                  </Tab>

                  <Tab disabled eventKey='currency' title='Currency'>
                    <div className='mt-3'>
                      <CurrenciesForm
                        isEdit
                        enableSubmit={enableSubmit}
                        setEnableSubmit={setEnableSubmit}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setSubmitting={setSubmitting}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setCurr={setCurr}
                        bonusDetail={bonusDetail}
                        setSelectedTab={setSelectedTab}
                        setActiveTab={setActiveTab}
                      />
                    </div>
                  </Tab>


                  {[BONUS_TYPE_CONSTANTS.DEPOSIT, BONUS_TYPE_CONSTANTS.CASINO_DEPOSIT, BONUS_TYPE_CONSTANTS.SPORTS_DEPOSIT].includes(values.bonusType) && <Tab disabled eventKey='countries' title='Countries'>
                    <div className='mt-4'>
                      <BonusCountry
                        data={countryData}
                        setData={setCountryData}
                        values={values}
                        setSelectedTab={setSelectedTab}
                        handleSubmit={handleSubmit}
                        languages={languages}
                        isEdit
                        bonusDetail={bonusDetail}
                      />
                    </div>
                  </Tab>}
                </Tabs>
              </BForm>
            )}
          </Formik>
        )
        : (
          <Preloader />
        )}
    </>
  )
}

export default EditBonus
