import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAffiliateCommissionLiveReportStart, getAffiliateCommissionReportStart, getAffiliateDashboardDataStart } from '../../../../store/redux-slices/affiliates'
import { subDays, startOfMonth } from 'date-fns';
import useDebounce from '../../../../hooks/useDebounce';

// const format = (date) => {
//   const inputDate = date.toLocaleDateString()
//   const regex = /(\d{2})\/(\d{2})\/(\d{4})/;
//   const match = inputDate.match(regex)
//   if (match) {
//     const day = match[1];
//     const month = match[2];
//     const year = match[3];

//     const formattedDate = `${year}-${month}-${day}`;
//     return formattedDate;
//   } else {
//     return 'Invalid date format';
//   }
// }

const useAffiliateStats = (affiliate) => {
  const { livePlayerloading: loading, livePlayerData } = useSelector((state) => state.dashboard)
  const [dashboardData, setDashboardData] = useState({
    newRegistredPlayers: 0,
    allPlayers: 0
  })
  const fetchDetails = () => { }
  const dispatch = useDispatch()
  const affiliateDashboard = useSelector((state) => state.affiliate?.affiliateDashboard)

  const getAffiliateCommissionsReport = useDebounce(() => {
    dispatch(
      getAffiliateCommissionLiveReportStart({
        ...(affiliate?.affiliateId ? { affiliateId: affiliate.affiliateId } : {}),
      })
    )
  }, 500);

  useEffect(() => {
    if (affiliateDashboard) {
      setDashboardData(affiliateDashboard)
    }
  }, [affiliateDashboard])

  useEffect(() => {
    if (affiliate) getAffiliateCommissionsReport();
  }, [affiliate]);

  return {
    loading,
    fetchDetails,
    affiliateDashboard,
    dashboardData
  }
}

export default useAffiliateStats
