import * as Yup from "yup";
import { GLOBAL_SETTINGS_KEY } from "../../../../utils/constant";
import FormatNumber from "../../../../utils/formatNumber";

const Site = (name) => `${GLOBAL_SETTINGS_KEY.SITE_INFORMATION}.${name}`;
const Admin = (name) => `${GLOBAL_SETTINGS_KEY.ADMIN_INFORMATION}.${name}`;
const SendGrid = (name) => `${GLOBAL_SETTINGS_KEY.SENDGRID}.${name}`;

export const globalSettingsFields = [
  { title: "Site" },

  { label: "Nome", name: Site("name"), type: "text", className: "col-sm-4 col-12" },
  { label: "Url", name: Site("url"), type: "text", className: "col-sm-4 col-12" },
  { label: "E-mail de suporte", name: Site("supportEmail"), type: "text", className: "col-sm-4 col-12" },

  { label: 'Playing now', feedback: "Mostra as transmissões ao vivo na página do cassino", name: Site("enablePlayinNowCasinoPage"), type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },
  { label: 'Sports', feedback: "Habilita os jogos de sports", name: Site("sports"), type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },

  { label: 'Site em Manutenção', name: Site("maintenance"), type: "switch", feedback: "Habilita a página de manutenção no site, tornando o site temporariamente indisponível", className: "col-12 bg-gray-200-hover rounded-5" },
  { label: "(Manutenção) Título", name: Site("maintenanceTitle"), type: "text", className: "col-sm-12 col-12", showIfValue: Site("maintenance") },
  { label: "(Manutenção) Descrição", name: Site("maintenanceDescription"), type: "text", as: "textarea", rows: "3", inputClassName: "h-100", className: "col-sm-12 col-12", showIfValue: Site("maintenance") },
  { label: "(Manutenção) Link", name: Site("maintenanceLink"), type: "text", className: "col-sm-12 col-12", showIfValue: Site("maintenance") },

  { separator: true },
  { label: "Valor minímo para acessar os Jogos", feedback: "Valor minímo (Dinheiro + Bônus) na carteira do usuário para acessar os jogos", name: Site("minimumAmountToAccessGames"), type: "currency", className: "col-sm-4 col-12" },
  { label: "Valor minímo para acessar as Lives", feedback: "Valor minímo (Dinheiro + Bônus) na carteira do usuário para acessar as lives", name: Site("minimumAmountToAccessLives"), type: "currency", className: "col-sm-4 col-12" },
  { label: "Tempo limite de live demo", feedback: "Valor em segundos", name: Site("liveDemoTime"), type: "number", className: "col-sm-4 col-12" },
  { label: "Valor minímo para apostar em Jogos", feedback: "Valor minímo (Dinheiro + Bônus) na carteira do usuário para apostar em jogos", name: Site("minimumAmountToBetGames"), type: "currency", className: "col-sm-4 col-12" },

  { title: "Painel Administrativo" },

  { label: "Login com código OTP", feedback: "Habilita a solicitação do código OTP ao fazer login como Administrador", name: Admin("enableOtpCodeAdminLogin"), type: "switch", className: "col-12 bg-gray-200-hover rounded-5" },

  { subtitle: "Send Grid" },
  { label: "E-mail", name: SendGrid("SENDGRID_EMAIL"), type: "text", className: "col-sm-6 col-12" },
  { label: "API Key", name: SendGrid("SENDGRID_API_KEY"), type: "text", className: "col-sm-6 col-12" },
]

export const globalSettingsFormInitialValues = (values = {}) => {

  return ({
    [GLOBAL_SETTINGS_KEY.SITE_INFORMATION]: {
      name: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.name || "",
      url: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.url || "",
      supportEmail: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.supportEmail || "",
      enablePlayinNowCasinoPage: Boolean(values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.enablePlayinNowCasinoPage),
      maintenance: Boolean(values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.maintenance),
      sports: Boolean(values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.sports),
      minimumAmountToAccessGames: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.minimumAmountToAccessGames || 1,
      minimumAmountToAccessLives: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.minimumAmountToAccessLives || 1,
      minimumAmountToBetGames: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.minimumAmountToBetGames || 1,
      liveDemoTime: values[GLOBAL_SETTINGS_KEY.SITE_INFORMATION]?.liveDemoTime || 30,
    },
    [GLOBAL_SETTINGS_KEY.ADMIN_INFORMATION]: {
      enableOtpCodeAdminLogin: Boolean(values[GLOBAL_SETTINGS_KEY.ADMIN_INFORMATION]?.enableOtpCodeAdminLogin),
    },
    [GLOBAL_SETTINGS_KEY.SENDGRID]: {
      SENDGRID_EMAIL: values[GLOBAL_SETTINGS_KEY.SENDGRID]?.SENDGRID_EMAIL || null,
      SENDGRID_API_KEY: values[GLOBAL_SETTINGS_KEY.SENDGRID]?.SENDGRID_API_KEY || null,
    },

  })
}

export const globalSettingsFormSchema = Yup.object().shape({
  [GLOBAL_SETTINGS_KEY.SITE_INFORMATION]: Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string(),
    supportEmail: Yup.string(),

    enablePlayinNowCasinoPage: Yup.boolean(),
    maintenance: Yup.boolean(),
    sports: Yup.boolean(),
    minimumAmountToAccessGames: Yup.number().min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
    minimumAmountToAccessLives: Yup.number().min(1, `Valor minímo de ${FormatNumber.currency(1)}`),
    minimumAmountToBetGames: Yup.number().min(0, `Valor minímo de ${FormatNumber.currency(0)}`),

    liveDemoTime: Yup.number().min(30, `Valor minímo de 30 segundos`),
  }),
  [GLOBAL_SETTINGS_KEY.ADMIN_INFORMATION]: Yup.object().shape({
    enableOtpCodeAdminLogin: Yup.boolean(),
  }),
  [GLOBAL_SETTINGS_KEY.SENDGRID]: Yup.object().shape({
    SENDGRID_EMAIL: Yup.string().required("E-mail do Send Grid é obrigatório"),
    SENDGRID_API_KEY: Yup.string().required("API key do Send Grid é obrigatório"),
  }),
})