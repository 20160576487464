import * as Yup from 'yup'

export const createTenantUserSchema = (userFields, user) =>
  userFields &&
  Yup.object().shape({
    email:
      userFields.email &&
      Yup.string().email('Invalid email').max(50).required('Email Required'),
    password:
      userFields.password && user
        ? Yup.string().matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Invalid Password'
        )
        : Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Invalid Password'
          )
          .max(50)
          .required('Password Required'),
    confirmPassword:
      userFields.confirmPassword && user
        ? Yup.string().when('password', {
          is: (value) => value && value.length > 0,
          then: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password Required'),
          otherwise: Yup.string()
        })
        : Yup.string()
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Invalid Password'
          )
          .max(50)
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Confirm Password Required'),
    firstName:
      userFields.firstName &&
      Yup.string()
        .min(3, 'First Name must be atleast 3 characters')
        .matches(/^[a-zA-Z0-9]*$/, 'Only Alphabets and Numbers allowed')
        .max(50, 'First Name must be atmost 50 characters').required('First Name Required'),
    lastName:
      userFields.lastName === 2
        ? Yup.string()
          .matches(/^[a-zA-Z0-9]*$/, 'Only Alphabets and Numbers allowed')
          .min(3, 'Last Name must be atleast 3 characters')
          .max(50, 'Last Name must be atmost 50 characters').required('Last Name Required')
        : Yup.string()
          .matches(/^[a-zA-Z0-9]*$/, 'Only Alphabets and Numbers allowed')
          .min(3, 'Last Name must be atleast 3 characters')
          .max(50, 'First Name must be atmost 50 characters').nullable(),
    phone: userFields.phone === 2
      ? Yup.string().required('Phone Required').nullable()
      : userFields.phone !== 0 &&
      Yup.string().nullable(),
    dateOfBirth:
      userFields.dateOfBirth === 2
        ? Yup.date()
          .max(new Date(), 'Date Of Birth Must be Earlier than Today')
          .typeError('Please enter a valid Date of Birth')
          .required('Invalid Date')
        : userFields.dateOfBirth !== 0 && Yup.date()
          .max(new Date(), 'Date Of Birth Must be Earlier than Today')
          .typeError('Please enter a valid Date of Birth').nullable(),
    gender: userFields.gender === 2
      ? Yup.string().required('Gender Required')
      : userFields.gender !== 0 && Yup.string().nullable(),
    username:
      userFields.username === 2
        ? Yup.string().max(50)
          .matches(/^[a-zA-Z0-9_]*$/, 'Only Alphabets, Numbers and UnderScore(_) allowed')
          .required('UserName Required')
        : userFields.username !== 0 && Yup.string().max(50)
          .matches(/^[a-zA-Z0-9_]*$/, 'Only Alphabets, Numbers and UnderScore(_) allowed')
          .nullable(),
    address: userFields.address === 2
      ? Yup.string().max(100, 'Maximum 100 Characters').required('Address Required').nullable()
      : userFields.address !== 0 && Yup.string().max(100, 'Maximum 100 Characters').nullable(),
    city: userFields.address === 2
      ? Yup.string().max(50, 'Maximum 50 Characters')
        .matches(/^[a-zA-Z -]*$/, 'Only Alphabets, Space and Dash(-) allowed')
        .required('City Required').nullable()
      : userFields.address !== 0 && Yup.string()
        .matches(/^[a-zA-Z -]*$/, 'Only Alphabets, Space and Dash(-) allowed')
        .max(50, 'Maximum 50 Characters').nullable(),
    zipCode: userFields.address === 2
      ? Yup.string().max(6, 'Maximum 6 Characters')
        .matches(/^[0-9]*$/, 'Only Numbers allowed')
        .required('Zip Code Required').nullable()
      : userFields.address !== 0 && Yup.string()
        .max(6, 'Maximum 6 Characters')
        .matches(/^[0-9]*$/, 'Only Numbers allowed')
        .nullable(),
    currencyCode:
      userFields.currencyCode &&
      Yup.string().required('Country Code Required'),
    countryCode: userFields.countryCode === 2
      ? Yup.string().required('Country Required')
      : userFields.countryCode !== 0 && Yup.string().nullable(),
    preferredLanguage:
      userFields.preferredLanguage === 2
        ? Yup.string().required('Preferred Language Required')
        : userFields.preferredLanguage !== 0 && Yup.string().nullable(),
    sms: userFields.sms === 2
      ? Yup.string().max(50).required('SMS Required')
      : userFields.sms !== 0 && Yup.string().max(50).nullable()
  })

export const depositSchema = () =>
  Yup.object().shape({
    addAmount: Yup.number()
      .typeError('Only numbers are allowed')
      .min(1, 'Amount shoud be greater than 0')
      .required('Amount Required')
  })

export const reasonSchema = () =>
  Yup.object().shape({
    reason: Yup.string()
      .max(100, 'Max 100 characters')
      .required('Reason Required')
  })

export const temporarySuspendReasonSchema = () =>
  Yup.object().shape({
    reasonSuspensionId: Yup.string('Motivo é obrigatório')
      .required('Motivo é obrigatório'),
    blockedUntil: Yup.string('Data de reativação é obrigatório')
      .required('Data de reativação é obrigatório')
  })