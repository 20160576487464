import React from "react";

import { motion } from "framer-motion/dist/framer-motion";
import { Button, Form, Row, Col, Table } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleDown,
  faArrowCircleUp,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";

import Trigger from "../../../components/OverlayTrigger";
// import DateRangePicker from '../../../components/DateRangePicker'
import { affiliateTableHeaders } from "./components/constants";
import useAllAffiliateListing from "./hooks/useAllAffiliateListing";
import PaginationComponent from "../../../components/Pagination";
import useCheckPermission from "../../../utils/checkPermission";
import { AdminsRoutes } from "../../../routes";
import ActionMenu from "./components/ActionMenu";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { ACTIVE_LABEL, ACTIVE_VALUE, AFFILIATE_TYPE, AFFILIATE_TYPE_LABEL_BY_VALUE, STATUS_LABEL, VISIBILYT_LABEL, VISIBILYT_VALUE } from "../../../utils/constant";
import useRole from "../../../hooks/useRole";
import { Link } from "react-router-dom";
import FormatDate from "../../../utils/formatDate";

const Affiliates = () => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    affiliatesData,
    totalPages,
    navigate,
    loading,
    getRole,
    show,
    setShow,
    active,
    handleShow,
    handleYes,
    parentIdAffiliate,
    setParentIdAffiliate,
    resetFilter,
    status,
    setStatus,
    setIsVisible,
    affiliateType,
    setAffiliateType,
  } = useAllAffiliateListing();
  const { isHidden } = useCheckPermission();
  const { AdminOrSuperAdmin } = useRole();

  return (
    <>
      <div className="section-header-wrap mt-n3 mx-n3">
        <Row>
          <Col lg={8} xs={7}>
            <h2 className="title-text">Affiliados</h2>
          </Col>
          <Col lg={4} xs={5}>
            {/* Button to create new affiliate user */}
            <div className="d-flex justify-content-end">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Button
                  variant="btn btn-primary w-100"
                  onClick={() => navigate(AdminsRoutes.CreateAffiliates)}
                  hidden={isHidden({
                    module: { key: "Affiliates", value: "C" },
                  })}
                  style={{ maxWidth: "140px" }}
                >
                  <AddCircleOutlineRoundedIcon className="me-2" />
                  <span>Criar</span>
                </Button>
              </motion.div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="FormsBg">
        <Row>
          <Col xs={12} sm={3} className="mb-3 mb-sm-0">
            <Form.Control
              type="search"
              placeholder="Pesquisar Email, Nome, Usuário"
              size="sm"
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, "")
                )
              }
            />
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-sm-0">
            <Form.Select
              size='sm'
              value={affiliateType}
              onChange={e =>
                setAffiliateType(Number(e.target.value))}
            >
              <option>Tipo de Afiliado</option>
              {Object.values(AFFILIATE_TYPE).map((value) => {
                return <option key={value} value={value}>{AFFILIATE_TYPE_LABEL_BY_VALUE[value]}</option>
              })}
            </Form.Select>
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-sm-0">
            <Form.Select
              size='sm'
              value={status}
              onChange={e =>
                setStatus(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            >
              <option>Status</option>
              {Object.keys(STATUS_LABEL).map((key) => {
                if (["pending", "approved"].includes(String(key).toLowerCase())) return <option key={key} value={String(key).toLowerCase()}>{STATUS_LABEL[key]}</option>
              })}
            </Form.Select>
          </Col>

          <Col xs={12} md={2} className="mb-3 mb-sm-0">
            <Form.Select
              size='sm'
              value={status}
              onChange={e =>
                setIsVisible(e.target.value.replace(/[~`!$%^&*#=)()><?]+/g, ''))}
            >
              <option className="d-none" >Visibilidate</option>
              <option>Todos</option>
              <option value={true}>Visível</option>
              <option value={false}>Não Visível</option>
            </Form.Select>
          </Col>

          {(AdminOrSuperAdmin) ? <Col xs={11} sm={2}>
            <Form.Control
              type="number"
              placeholder="ID de Afiliado Pai"
              size="sm"
              value={parentIdAffiliate}
              onChange={(event) =>
                setParentIdAffiliate(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, "")
                )
              }
            />
          </Col> : null}

          <Col xs={1}>
            <div className="d-flex justify-content-end align-item-center">
              <Trigger message="Resetar Filtros">
                <Button size="sm" onClick={resetFilter}>
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </Trigger>
            </div>
          </Col>
        </Row>
      </div>

      <div className="TableBg mt-4 pt-1">
        <Table striped responsive hover size="sm" className="text-center mt-3">
          <thead className="thead-light">
            <tr>
              {affiliateTableHeaders.map((h, idx) => (
                <th
                  key={idx}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {h.label}{" "}
                </th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? "cover-spin" : ""}>
            {!loading &&
              Boolean(affiliatesData) &&
              affiliatesData?.rows?.map(
                ({
                  affiliateId,
                  affiliateType,
                  email,
                  isActive,
                  userId,
                  firstName,
                  lastName,
                  username,
                  status,
                  commission,
                  adminIdAffiliate,
                  isVisible,
                  affiliateWallet,
                  parentIdAffiliate,
                  createdAt
                }) => {
                  let notifyMessage = null;
                  if (!affiliateWallet) notifyMessage = 'Atualize os dados do afiliado para criação da carteira!'

                  return (
                    <tr key={affiliateId} >
                      <Trigger message={notifyMessage} placement='top'><td><span data-notify={!affiliateWallet ?? undefined}>{affiliateId}</span></td></Trigger>
                      <td className="text-left">
                        {username ? (
                          <Trigger message={username}>
                            <Link to={`/admin/affiliate-details/${affiliateId}`}>
                              <span
                                style={{
                                  width: "100px",
                                  cursor: "pointer",
                                }}
                                className="text-link d-inline-block text-truncate"
                              >
                                {username}
                              </span>
                            </Link>
                          </Trigger>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="text-left">{AFFILIATE_TYPE_LABEL_BY_VALUE[affiliateType]}</td>
                      <td className="text-left">{email}</td>
                      <td className="text-left">{firstName}</td>
                      <td className="text-left">{lastName}</td>
                      <td>{status ? <span className="w-100" data-badge={status}>{STATUS_LABEL[String(status).toUpperCase()]}</span> : "N/A"}</td>
                      <td><span className="w-100" data-badge={ACTIVE_VALUE[isActive]}>{ACTIVE_LABEL[isActive]}</span></td>
                      <td><span className="w-100" data-badge={VISIBILYT_VALUE[isVisible]}>{VISIBILYT_LABEL[isVisible]}</span></td>
                      <td>{Boolean(parentIdAffiliate) ? "Sim" : "Não"}</td>
                      {/* <td>{commission?.parentAffiliateCommission || 0}%</td> */}
                      <td>{commission?.commission || 0}%</td>
                      <td>{FormatDate.default(createdAt)}</td>
                      <td>
                        <ActionMenu userId={userId} navigate={navigate} isActive={isActive} isHidden={isHidden} getRole={getRole} handleShow={() => {
                          handleShow(affiliateId, isActive, username, adminIdAffiliate)
                        }} affiliateId={affiliateId} />
                      </td>
                    </tr>
                  );
                }
              )}

            {affiliatesData?.count === 0 && !loading && (
              <tr>
                <td colSpan={13} className="text-danger text-center">
                  Nenhum dado encontrado
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>Total de registros ({affiliatesData?.count || 0})</div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {affiliatesData?.count !== 0 && !loading && (
              <PaginationComponent
                className="m-0"
                page={affiliatesData?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
          </div>
        </div>

      </div>

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={active}
          name={'name'}
        />
      )}
    </>
  );
};

export default Affiliates;
