import { BONUS_STATUS_INT, BONUS_STATUS_LABEL } from "../../../utils/constant"
import { bonusType } from "../Bonus/components/CreateBonus/constants"
import _ from "lodash";

export const reasonOptions = [
  'Image Not Visible Properly',
  'Not A Valid Document',
  'Document Validation Expired',
  'Add Custom Reason'
]

export const tableHeaders = [
  { label: 'Id', value: 'userBonusId' },
  { label: 'Nome', value: 'promotionTitle' },
  { label: 'Tipo de bônus', value: 'bonusType' },
  { label: 'Valor para apostar', value: 'amountToWager' },
  { label: 'Valor apostado', value: 'wageredAmount' },
  { label: 'Status', value: 'isActive' },
  { label: 'Criado em:', value: 'createdAt' },
  { label: 'Ativado em:', value: 'activatedAt' },
  { label: 'Reivindicado em:', value: 'claimedAt' },
  { label: 'Expirado em:', value: 'expiresAt' },
  { label: 'Atualizado em:', value: 'expiresAt' },
  { label: 'Action', value: 'action' }
]

export const bonusStatus = [
  { label: 'Todos', value: '' },
  ...Object
    .keys(_.pick(BONUS_STATUS_INT, [0, 1, 2, 3, 4, 5, 6]))
    .map(value => ({ value, label: BONUS_STATUS_LABEL[value] }))
]

export const bonusTypes = [
  { label: 'Todos', value: '' },
  ...bonusType,
]

export const affiliateList = [
  { label: 'Parent Affiliate ID', value: 'parentIdAffiliate' },
  { label: 'Parent Username', value: 'parentUsername' },
  { label: 'Affiliate ID', value: 'affiliateId' },
  { label: 'User Name', value: 'username' },
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Country', value: 'country' },
  { label: 'Currency', value: 'currency' },
  { label: 'Mobile', value: 'mobile' }
]

export const userDisableReasons = {
  bonus_abuser: "Abuso de bônus",
  duplicate_account: "Conta duplicada",
  fraudulent: "Fraudulento",
  risk_country: "País de risco",
  rude_behaviour: "Comportamento rude",
  banned_country_connect_with_vpn_or_yor_browser: "País Banido - Conecte com VPN ou navegador Tor'",
  kyc_declined: "KYC Recusado",
  suspicios_constant_deposits_and_withdrawals_money_laundering: "Suspeito de depósitos e retiradas constantes (lavagem de dinheiro)",
  contacts_support_for_bigger_amount_of_deposit_than_allowed: "Entra em contato com o suporte para um valor de depósito maior do que o permitido",
  resonsible_gambling: "Jogo responsável",
  pending: "Pendente",
  frozen: "Congelado",
  deactivated: "Desativado",
  perm_banned: "Banido permanentemente",
  temp_banned_blocked: "Banido temporariamente",
  gambling_issues: "Problemas com jogos de azar",
  self_excluded: "Autoexcluído",
  enforced_self_ex: "Autoexposição forçada",
  location_ban: "Proibição de localização",
  other: "Outro",
}
