import {
  Card,
  Col,
  Dropdown,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import React from "react";
import "./style.css";
import ResponsibleGaming from "../../../../components/ResponsibleGaming";
import Affiliate from "./Affiliate";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../../components/Button";
import useRole from "../../../../hooks/useRole";

const Overview = ({
  basicInfo,
  kycInfo,
  contactInfo,
  user,
  affiliate,
  setActiveInactiveModal,
  setIsInternalModal,
  setVerifyEmailModal,
  setShowModal,
  setFreeSpinModal,
  setShowManageMoneyModal,
  setShowAddAffiliate,
  addUserAffiliateLoading,
  setShowRemoveAffiliate,
  updateUserAffiliateLoading,
  isHidden,
  setPasswordEmail,
  setUserPassword,
  setEditModal,
  addTag,
  getDuplicateUsers,
  duplicateUsers,
  setApproveAffiliateModal,
  setRejectAffiliateModal,
}) => {
  const navigate = useNavigate();
  const { AdminOrSuperAdmin, Affiliate } = useRole();
  return (
    <>
      <Row>
        <Col lg={AdminOrSuperAdmin ? 4 : 12} xs={12} className="">
          <Card className="card-overview">
            <h4 className="h4-overview text-center mt-1">
              <div className="d-flex align-items-center justify-content-between">
                <span>Informações básicas</span>
                <Button
                  variant="outline-danger"
                  size={"sm"}
                  className='mb-3'
                  hidden={!affiliate || affiliate?.isRegistered || !Affiliate}
                  onClick={() => setRejectAffiliateModal(true)}
                >
                  Rejeitar Afiliado
                </Button>
                <Button
                  variant="outline-success"
                  size={"sm"}
                  className='mb-3'
                  hidden={!affiliate || affiliate?.isRegistered || !Affiliate}
                  onClick={() => setApproveAffiliateModal(true)}
                >
                  Aprovar Afiliado
                </Button>
              </div>

              <hr className="h4-hr mt-1" />
            </h4>
            {/* <div className='div-overview'> */}
            {basicInfo?.map(({ label, value, subValue }) => {
              return user?.kycMethod !== 1 && label === "Applicant Id"
                ? ""
                : (label === "Reason" && value ? true : label !== "Reason") && (
                  <div
                    key={label}
                    className="d-flex justify-content-between m-1"
                  >
                    <h6 className="px-2 overview-leftlabel">{label}</h6>
                    <span className={`${subValue} px-2`}>
                      {value || "N/A"}
                    </span>
                  </div>
                );
            })}
          </Card>
        </Col>
        {AdminOrSuperAdmin ? <>
          <Col lg={4} xs={12} className="mt-3 mt-lg-0">
            <Card className="card-overview">
              <h4 className="h4-overview text-center mt-1">
                Ações <hr className="h4-hr mt-1" />
              </h4>
              <div className="div-overview p-3 pt-0">

                <Button
                  variant="outline-success"
                  className='w-100 mb-3'
                  hidden={!affiliate || affiliate?.isRegistered}
                  onClick={() => setApproveAffiliateModal(true)}
                >
                  Aprovar Afiliado
                </Button>
                <Button
                  variant="outline-danger"
                  className='w-100 mb-3'
                  hidden={!affiliate || affiliate?.isRegistered}
                  onClick={() => setRejectAffiliateModal(true)}
                >
                  Rejeitar Afiliado
                </Button>

                <Button
                  className='w-100 mb-3'
                  title="Gerenciar dinheiro"
                  variant='outline-success'
                  hidden={!affiliate?.isRegistered || isHidden({ module: { key: 'Users', value: 'AB' } })}
                  onClick={() => setShowManageMoneyModal(true)}
                >
                  Gerenciar dinheiro
                </Button>

                <Button
                  variant="outline-warning"
                  className='w-100 mb-3'
                  hidden={isHidden({ module: { key: "Affiliates", value: "U" } })}
                  onClick={() =>
                    navigate(`/admin/update-affiliate/${affiliate.affiliateId}`)
                  }
                >
                  Editar
                </Button>

                {!isHidden({ module: { key: "Affiliates", value: "U" } }) && (
                  <Dropdown className="d-inline">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      className='w-100 mb-3'
                      variant="outline-success"
                    >
                      Alterar senha
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="user-dropdown user">
                      {/* <Dropdown.Item onClick={() => setPasswordEmail(true)}>
                      Send Email
                    </Dropdown.Item> */}
                      <Dropdown.Item onClick={() => setUserPassword(true)}>
                        Alterar senha
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </Card>
          </Col>
          <Col lg={4} xs={12} className="mt-3 mt-lg-0">
            <Card className="card-overview">
              <h4 className="h4-overview text-center mt-1">
                Outras informações <hr className="h4-hr mt-1" />
              </h4>
              <h5 className="overview-subhead mt-2">Informações do Afiliado Pai</h5>
              <div className="d-flex justify-content-between m-1">
                <h6 className="px-2 overview-leftlabel">Token</h6>
                <span className='px-2'>{affiliate?.parentIdAffiliate ? <Link to={`/admin/affiliate-details/${affiliate?.parentIdAffiliate}`} target='_blank'>{affiliate?.parentAffiliate?.username}</Link> : "N/A"}</span>
              </div>
              <div className="d-flex justify-content-between m-1">
                <h6 className="px-2 overview-leftlabel">Status</h6>
                {affiliate?.parentIdAffiliate ? (
                  <span className="text-success px-2">Vinculado</span>
                ) : (
                  <span className="text-danger px-2">Não Vinculado</span>
                )}
              </div>
              {/* </div> */}
              {/* <h5 className="overview-subhead mt-2">Informações do KYC </h5>
              {kycInfo?.map(({ label, value, subValue }) => {
                return user?.kycMethod !== 1 && label === "Applicant Id"
                  ? ""
                  : (label === "Reason" && value ? true : label !== "Reason") && (
                    <div
                      key={label}
                      className="d-flex justify-content-between m-1"
                    >
                      <h6 className="px-2 overview-leftlabel">{label}</h6>
                      <span className={`${subValue} px-2`}>
                        {value || "N/A"}
                      </span>
                    </div>
                  );
              })} */}
              {affiliate?.registerQuestion ? <>
                <h5 className="overview-subhead mt-2">Motivo para se tornar um Afiliado</h5>
                <p className="mx-2 px-1">{affiliate.registerQuestion}</p>
              </> : null}

            </Card>
          </Col>
        </> : null}
      </Row>

      {/* <Row>
        {user?.affiliate && (
          <Col xs={5}>
            <Affiliate userData={user} />
          </Col>
        )}
      </Row> */}
    </>
  );
};

export default Overview;
