export const tableHeaders = [
  { label: 'Id', value: 'superAdminUserId' },
  { label: 'Título', value: 'title', className: 'text-left' },
  // { label: 'Owner Name', value: 'ownerName' },
  // { label: 'Portal Name', value: 'tenantName' },
  { label: 'Tipo de bônus', value: 'bonusType', className: 'text-left' },
  // { label: 'Valid Till', value: 'validTo' },
  // { label: 'Is Expired', value: 'isExpired' },
  { label: 'Multiplicador de apostas', value: 'wageringMultiplier' },
  { label: 'Dias para liberar (apostas)', value: 'daysToClear' },
  { label: 'Bonus Code', value: 'bonusCode', className: 'text-left' },
  { label: 'Ativado', value: 'activatedCount' },
  { label: 'Reivindicado', value: 'claimedCount' },
  { label: 'Status', value: 'isActive' },
  { label: 'Visível em Promoções', value: 'visibleInPromotions' },
  { label: 'Ação', value: 'superAdminUserId' }
]
