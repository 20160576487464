import { faInfo, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, Col, Form, Row, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import { BonusInfoModal } from '../../../../components/InfoModals'
import Trigger from '../../../../components/OverlayTrigger'
import PaginationComponent from '../../../../components/Pagination'
import Preloader, { Loading } from '../../../../components/Preloader'
import useCheckPermission from '../../../../utils/checkPermission'
import { formatDate } from '../../../../utils/dateFormatter'
import { bonusStatus, bonusTypes, tableHeaders } from '../constants'
import useYourBonuses from '../hooks/useYourBonuses'
import ActionMenu from '../ActionMenu'
import FormatDate from '../../../../utils/formatDate'
import FormatNumber from '../../../../utils/formatNumber'
import { BONUS_STATUS_INT, BONUS_STATUS_LABEL } from '../../../../utils/constant'

const YourBonuses = ({
  currencyCode
}) => {
  const {
    loading,
    userBonus,
    limit,
    page,
    totalPages,
    status,
    bonusType,
    cancelBonusHandler,
    setBonusType,
    setStatus,
    setLimit,
    setPage,
    infoModal,
    setInfoModal,
    bonusData,
    setBonusData
  } = useYourBonuses()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <div className='FormsBg'>
        <Row>
          <Col xs='12' sm='3'>
            <Form.Label>Tipo de bônus</Form.Label>
            <Form.Select className='mr-2 form-control'
              name='bonusType'
              value={bonusType}
              onChange={(e) => setBonusType(e.target.value)}
            >
              {bonusTypes.map((item) => {
                return (
                  item.value !== 'cashfreespins' &&
                  <option key={`bonusType ${item.value}`} value={item.value}>
                    {item?.label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
          <Col xs='12' sm='3'>
            <Form.Label>Status</Form.Label>
            <Form.Select
              name='isActive'
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {bonusStatus?.map(({ label, value }, i) => {
                return (
                  <option value={value} key={`player-your-bonuses-status-filter ${i}`}>
                    {label}
                  </option>
                )
              })}
            </Form.Select>
          </Col>
        </Row>
      </div>
      <div className='TableBg mt-4 pt-1'>
        <div className='position-relative h-100'>
          <Table striped responsive hover size='sm' className='text-center mt-4'>

            <thead className='thead-light'>
              <tr>
                {tableHeaders.map((h, idx) => (
                  <th
                    key={`player-your-bonuses-heading ${idx}`}
                  >
                    {h.label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {userBonus?.rows?.map((item, i) => {
                return (
                  <tr key={`player-your-bonuses-row ${i}`}>
                    <td>{item?.userBonusId}</td>
                    <td>{item?.other?.bonusTitle || item?.bonus?.promotionTitle?.EN}</td>
                    <td>{item?.bonusType}</td>
                    <td>{FormatNumber.currency(item?.amountToWager)}</td>
                    <td>{FormatNumber.currency(item?.wageredAmount)}</td>
                    <td style={{ minWidth: "9rem" }}>
                      <span className='w-100' data-badge={String(BONUS_STATUS_INT[item?.statusInt]).toLowerCase()} >{BONUS_STATUS_LABEL[item?.statusInt]}</span>
                    </td>
                    <td>{FormatDate.default(item?.createdAt)}</td>
                    <td>{FormatDate.default(item?.activatedAt)}</td>
                    <td>{FormatDate.default(item?.claimedAt)}</td>
                    <td>{FormatDate.default(item?.statusInt == BONUS_STATUS_INT.EXPIRED && item?.expireAt)}</td>
                    <td>{FormatDate.default(item?.updatedAt)}</td>
                    <td>
                      {/* <ButtonGroup> */}
                      {!isHidden({ module: { key: 'Bonus', value: 'Issue' } }) &&

                        <ActionMenu
                          item={item}
                          cancelBonusHandler={cancelBonusHandler}
                          setBonusData={setBonusData}
                          setInfoModal={setInfoModal}
                        />
                      }
                    </td>
                  </tr>
                )
              })}

              {userBonus?.count === 0 && (
                <tr>
                  <td colSpan={13} className='text-danger text-center'>
                    Nenhum dado encontrado
                  </td>
                </tr>
              )}

            </tbody>
          </Table>
          {loading ? <Loading className={"position-absolute bg-white top-0 w-100 h-100"} /> : null}
        </div>
        <div className='row px-3 mt-3'>
          <div className='col-sm-6 col-12 mb-3 mb-sm-0 justify-content-end justify-content-sm-start  d-flex align-items-center'>
            Total de registros ({userBonus?.count || 0})
          </div>
          <div className='col-sm-6 col-12 d-flex justify-content-end'>
            {userBonus?.count > 0 && !loading && (
              <PaginationComponent
                className="m-0"
                page={userBonus?.count < page ? setPage(1) : page}
                totalPages={totalPages}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
              />
            )}
          </div>
        </div>
      </div>

      {infoModal &&
        <BonusInfoModal
          show={infoModal}
          setShow={setInfoModal}
          bonusData={bonusData}
          currencyCode={currencyCode}
        />}
    </>
  )
}

export default YourBonuses
