import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { addUserInternalTagtStart, getAllBonusStart } from '../../../store/redux-slices/admins'
import { getBonusStart, issueBonusStart } from '../../../store/redux-slices/bonus'
import { getCountriesStart, getSAUserDocumentStart, getUserStart, verifyUserDocumentStart } from '../../../store/redux-slices/fetchData'
import { getDocumentLabelStart, updateDocumentStart, updateSAUserStatusCompleted, updateSAUserStatusStart } from '../../../store/redux-slices/superAdminSettings'
import { addAffiliateUserStart, addLoyaltyPointsToUserStart, getDuplicateUsersStart, resetPasswordEmailStart, resetUserPasswordStart, updateTagsStart, updateUserAffiliateStart, verifyUserEmailStart } from '../../../store/redux-slices/tenantUsers'
import { getDocumentLabel, logoutUser, updateSAUserStatus } from '../../../utils/apiCalls'
import { formatDateYMD } from '../../../utils/dateFormatter'
import { safeStringify } from '../../../utils/helpers'
import FormatNumber from '../../../utils/formatNumber'
import FormatDate from '../../../utils/formatDate'
import { toast } from '../../../components/Toast'
import { ACTIVE_LABEL, ACTIVE_VALUE, ROLLOVER_TYPE_VALUE, STATUS_LABEL } from '../../../utils/constant'
import classNames from 'classnames'
// import { safeStringify } from '../../../utils/helpers'

const useUserDetails = () => {
  const [selectedTab, setSelectedTab] = useState('overview')
  const { userData, loading, updateUserAffiliateLoading, addUserAffiliateLoading } = useSelector((state) => state.fetch)

  const { documentLabels } = useSelector(state => state.superAdminSettings)
  const { bonusList } = useSelector((state) => state.admins)
  const { bonusDetail } = useSelector((state) => state.bonus)
  const { userId } = useParams()
  const dispatch = useDispatch()
  const { userDocuments } = useSelector(state => state.fetch)
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('')
  const [userDocumentId, setUserDocumentId] = useState('')
  const [showReasonModal, setShowReasonModal] = useState(false)
  const [enable, setEnable] = useState(false)
  const [docLabels, setDocLabels] = useState("")
  const [title, setTitle] = useState('')
  const [imagePreviewModalShow, setImagePreviewModalShow] = useState(false)
  const [imageUrl, setImageUrl] = useState({ name: '', preview: [] })
  const [showModal, setShowModal] = useState(false)
  const [freespinModal, setFreeSpinModal] = useState(false)
  const [showManageMoneyModal, setShowManageMoneyModal] = useState(false)
  const [selectedBonus, setSelectedBonus] = useState()
  const [bonusLoading, setBonusLoading] = useState(false)
  const [bonusAmount, setBonusAmount] = useState()
  const [isInternalModal, setIsInternalModal] = useState(false)
  const [name, setName] = useState('')
  const [showRemoveAffiliate, setShowRemoveAffiliate] = useState(false)
  const [showAddAffiliate, setShowAddAffiliate] = useState(false)
  const [labelName, setLabelName] = useState('')
  const [isActiveInactivemodalVisible, setActiveInactiveModal] = useState(false)
  const [verifyEmailModal, setVerifyEmailModal] = useState(false)
  const [userPasswordModal, setUserPasswordModal] = useState(false)
  const [passwordEmailModal, setPasswordEmailModal] = useState(false)

  const [editUserModal, setEditUserModal] = useState(false)
  const navigate = useNavigate()
  const [tagOptions, setTagOptions] = useState()
  const [selectedTag, setSelectedTag] = useState()
  const { duplicateUsers } = useSelector(state => state.tenantUsers)
  const [showTagModal, setShowTagModal] = useState(false)
  const [showDuplicateModal, setShowDuplicateModal] = useState(false)
  const [limit, setLimit] = useState(15)
  const [pageNo, setPageNo] = useState(1)
  const [sendLoyaltyPointsToUserModal, setSendLoyaltyPointsToUserModal] = useState(false)
  const [logoutUserLoading, setLogoutUserLoading] = useState(false)
  const [updateUserStatusLoading, setUpdateUserStatusLoading] = useState(false)

  const handleClose = () => {
    setShowReasonModal(false)
    setEnable(false)
  }

  const handleVerifyEmail = () => {
    dispatch(verifyUserEmailStart({ isTenant: false, data: { userId: parseInt(userId) } }))
    setVerifyEmailModal(false)
  }

  const fetchSABonus = () => {
    dispatch(getAllBonusStart({
      adminId: '',
      tenantId: '',
      limit: '',
      pageNo: '',
      search: '',
      bonusType: safeStringify(['deposit']),
      isActive: ''
    }))
  }

  useEffect(() => {
    let timer = null;
    if (bonusLoading) {
      timer = setTimeout(() => {
        setBonusLoading(false)
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [bonusLoading])

  const getUserDocuments = () => {
    dispatch(getSAUserDocumentStart({ userId }))
    dispatch(getDocumentLabelStart({ userId }))

  }

  useEffect(() => {
    dispatch(getUserStart({ userId }));
    getUserDocuments();
    fetchSABonus();
  }, [userId])

  const handleYes = (reason, request, labelName) => {
    if (request === 'request' || request === 'cancel') {
      dispatch(updateDocumentStart({ data: { labelName, documentLabelId: userDocumentId, reason, userId: parseInt(userId), reRequested: true }, isRequested: (request === 'request') }))
    } else {
      dispatch(verifyUserDocumentStart({ userId, data: { userDocumentId, reason, status, userId: parseInt(userId) } }))
    }
    setShow(false)
    setShowReasonModal(false)
    setStatus('')
  }

  const handleVerify = (modalStatus, userDocumentId, myName) => {
    setStatus(modalStatus)
    setName(myName)
    setUserDocumentId(userDocumentId)
    if (modalStatus === 'approved') {
      setShow(true)
      setShowReasonModal(false)
    } else {
      setShow(false)
      setShowReasonModal(true)
    }
    setTitle('Rejecting')
  }

  useEffect(() => {
    if (!docLabels) {
      async function fetchData() {
        await getDocumentLabel('').then((res) => {
          setDocLabels(res?.data?.data?.documentLabel)
        })
      }
      fetchData()
    }
    dispatch(getCountriesStart({ limit: '', name: '', pageNo: '', isActive: '', kycMethod: '' }))
  }, [])

  // useEffect(() => {
  //   dispatch(getAllBonusStart({ userId, bonusType: safeStringify(['match', 'balance', 'wagering']) }))
  // }, [showModal])

  useEffect(() => {
    if (!selectedBonus && bonusList?.length > 0) {
      setSelectedBonus(bonusList?.[0])
    }
    if (selectedBonus) {
      dispatch(getBonusStart({ userBonusId: '', bonusId: selectedBonus?.bonusId, isTenant: false }))
    }
  }, [bonusList, selectedBonus?.bonusId])

  const updateDocument = ({ documentLabelId, isRequested, labelName }) => {
    dispatch(updateDocumentStart({ data: { labelName, documentLabelId, userId: parseInt(userId) }, isRequested }))
  }

  const handleReRequest = (docId, myStatus, myName, myLabelName) => {
    setStatus('')
    setName(myName)
    setLabelName(myLabelName)
    if (myStatus === 'cancel') {
      setShow(true)
      setShowReasonModal(false)
    } else {
      setShow(false)
      setShowReasonModal(true)
    }
    setUserDocumentId(docId)
    setTitle('Re-Requesting')
  }

  const handleImagePreview = (documentUrl, name) => {
    setImageUrl({ name, preview: documentUrl })
    setImagePreviewModalShow(true)
  }

  const issueBonusHandler = () => {
    setShowModal(false)
    dispatch(issueBonusStart({ data: { userId: parseInt(userId), bonusId: selectedBonus?.bonusId, amount: bonusAmount } }))
    setBonusAmount('')
  }

  const showStyle = (data) => (data ? 'text-success' : 'text-danger')
  const printData = (data) => (data ? 'Sim' : 'Não')

  const removeAffiliateHandler = () => {
    dispatch(updateUserAffiliateStart({ data: { userId, tenantId: userData?.tenantId, affiliateStatus: false }, isSuperAdmin: true, setShowRemoveAffiliate }))
  }

  const addAffiliateHandler = () => {
    if (userData?.trackingToken && userData?.isAffiliateUpdated === false) {
      dispatch(addAffiliateUserStart({ data: { userId, tenantId: userData?.tenantId, trackingToken: userData?.trackingToken }, isSuperAdmin: true, setShowAddAffiliate }))
    }
  }

  const userTemporarySuspension = userData?.userTemporarySuspension && userData?.userTemporarySuspension[0];

  const basicInfo = [
    { label: 'ID', value: userId },
    { label: 'Carteira', value: FormatNumber.currency(userData?.userWallet?.amount || 0) },
    { label: 'Total de GGR', value: FormatNumber.currency(userData?.totalGgr || 0), subValue: showStyle(userData?.totalGgr > 0) },
    { label: 'Usuário', value: userData?.username },
    { label: 'E-mail', value: userData?.email },
    { label: 'Último login', value: FormatDate.toDateHour(userData?.lastLoginDate) },
    { label: 'IP de login', value: userData?.loginIp },
    { label: 'IP de Cadastro', value: userData?.signUpIp },
    { label: 'Nome completo', value: `${userData?.firstName ?? ""} ${userData?.lastName ?? ""}` },
    { label: 'CPF', value: userData?.cpf },
    { label: 'Data de nascimento', value: FormatDate.toDateOfBirth(userData?.dateOfBirth) },
    { label: 'Cadastrado em', value: FormatDate.toDateOfBirth(userData?.createdAt) },
    { label: 'Status', value: <span className="w-100" data-badge={ACTIVE_VALUE[userTemporarySuspension ? 'suspended' : userData?.isActive]}>{ACTIVE_LABEL[userTemporarySuspension ? 'suspended' : userData?.isActive]}</span> },
    ...(userTemporarySuspension?.isBlocked ? [
      { label: 'Motivo Suspensão', value: userTemporarySuspension?.reasonForSuspension?.reason.PT || 'N/A' }
    ] : []),
    ...((!userData?.isActive && userData?.defaultDisableReason) ? [{
      label: 'Motivo Inativo', value: userData?.defaultDisableReason || 'N/A'
    }] : []),
    ...((!userData?.isActive && userData?.reason) ? [{
      label: 'Motivo', value: userData?.reason || 'N/A'
    }] : []),

    { label: 'Email Verificado', value: printData(userData?.isEmailVerified), subValue: showStyle(userData?.isEmailVerified) },
    {
      label: 'Tags',
      value: userData?.tags
        ? userData?.tags?.length < 1 ? 'N/A' : userData?.tags?.join(', ')
        : 'N/A'
    },

    ...((userData?.applicantId) ? [{
      label: 'ID do candidato SumSub', value: userData?.applicantId || 'N/A'
    }] : []),
    ...((userData?.loyaltyPoints) ? [{
      label: 'Pontos de fidelidade', value: userData?.loyaltyPoints || 'N/A'
    }] : []),
  ]

  const kycInfo = [
    { label: 'KYC Método', value: userData?.kycMethod === 1 ? 'Sumsub' : 'System KYC' },
    { label: 'KYC Status', value: userData && <span className="w-100" data-badge={String(userData?.kycStatus).toLowerCase()}>{STATUS_LABEL[userData?.kycStatus]}</span> }
  ];

  const contactInfo = [
    { label: 'Telefone', value: userData?.phone },
    { label: 'Endereço', value: classNames(userData?.address, userData?.city, userData?.zipCode).split(" ").join(", ") },
    { label: 'Código do país', value: userData?.countryCode },
    { label: 'Notícias', value: printData(userData?.newsLetter), subValue: showStyle(userData?.newsLetter) },
    { label: 'SMS', value: printData(userData?.sms), subValue: showStyle(userData?.sms) }
  ];

  const rolloverInfo = [
    { label: 'Multiplicador', value: `${userData?.userRolloverData?.rolloverMultiplier || 0}x` },
    { label: 'Tipo', value: userData?.userRolloverData?.rolloverType ? ROLLOVER_TYPE_VALUE[userData?.userRolloverData?.rolloverType] : null },
    { label: 'Meta de apostas', value: FormatNumber.currency(userData?.userRolloverData?.rolloverMetaAmount || 0) },
    { label: 'Total de apostas', value: FormatNumber.currency(userData?.userRolloverData?.totalCasinoSportsBetAmount || 0) },
    { label: 'Progresso', value: `${userData?.userRolloverData?.rolloverMetaProgress || 0}%` },
    { label: 'Saque Liberado', value: printData(userData?.userRolloverData?.allowWithdraw), subValue: showStyle(userData?.userRolloverData?.allowWithdraw) },
    { label: 'Configuração', value: userData?.userRolloverData?.rolloverSettingsType },
  ];

  const internalPlayerHandler = async () => {
    dispatch(addUserInternalTagtStart({ data: { userId } }))
    setIsInternalModal(false)
    setTimeout(() => {
      dispatch(getUserStart({ userId }))
      dispatch(getSAUserDocumentStart({ userId }))
      dispatch(getDocumentLabelStart({ userId: parseInt(userId) }))
    }, 1000)
  }

  const updateUserStatus = async (res) => {
    setUpdateUserStatusLoading(true);
    await updateSAUserStatus({
      ...res,
      code: 'USER',
      userId,
      status: !userData.isActive,
      tenantId: userData.tenantId
    })
    dispatch(getUserStart({ userId }))
    dispatch(getSAUserDocumentStart({ userId }))
    dispatch(getDocumentLabelStart({ userId: parseInt(userId) }))
    dispatch(updateSAUserStatusCompleted())
    toast('Status do usuário alterado com sucesso!', 'success');
    setUpdateUserStatusLoading(false);

  }

  const handleResetPasswordEmail = () => {
    dispatch(resetPasswordEmailStart({
      isTenant: false,
      data: { userId: +userId }
    }))
    setPasswordEmailModal(false)
  }

  const handleResetUserPassword = (data) => {
    dispatch(resetUserPasswordStart({
      isTenant: false,
      ...data
    }))
    setUserPasswordModal(false)
  }

  const handleLogoutUser = async () => {
    setLogoutUserLoading(true)
    try {
      await logoutUser({ userId });
      toast("Usuário deslogado com sucesso!", "success")
    } catch (err) {
      toast("Erro ao deslogar usuário", "error")
    } finally {
      setLogoutUserLoading(false)
    }
  }

  useEffect(() => {
    if (userData?.tags?.length > 0) {
      const options = []
      for (const i in userData?.tags) {
        if (userData?.tags[i] !== '' && userData?.tags[i] !== null) {
          options.push({ label: userData?.tags[i], value: userData?.tags[i] })
        }
      }
      setTagOptions(options)
    }
  }, [userData?.tags])

  useEffect(() => {
    const tag = []
    for (const i in userData?.tags) {
      userData?.tags[i] !== null && tag.push({ label: userData?.tags[i], value: userData?.tags[i] })
    }
    setSelectedTag(tag)
  }, [userData?.tags])

  const updateTags = (formValues) => {
    const tags = []
    let customTag = false
    for (const tag in formValues?.tags) {
      tags.push(formValues?.tags[tag]?.value)
      if (formValues?.tags[tag]?.isNew) {
        customTag = true
      }
    }
    dispatch(updateTagsStart({
      data: {
        tenantId: userData?.tenantId && JSON.parse(userData?.tenantId),
        userId: userId && JSON.parse(userId),
        parentId: userData.parentId,
        tags,
        customTag
      },
      isTenant: false
    }))
  }

  const addTag = () => {
    setShowTagModal(true)
  }

  const getDuplicateUsers = () => {
    setShowDuplicateModal(true)
  }

  useEffect(() => {
    dispatch(getDuplicateUsersStart({
      limit,
      pageNo,
      userId,
      isTenant: false
    }))
  }, [limit, pageNo])

  const handleAddLoyaltyPointsToUser = (payload) => {
    // console.log('run :>> ',);
    const { loyaltyPoint } = payload
    dispatch(addLoyaltyPointsToUserStart({ userId: parseInt(userId), points: loyaltyPoint, setSendLoyaltyPointsToUserModal }))
  }
  return {
    bonusList,
    selectedTab,
    userData,
    loading,
    userDocuments,
    documentLabels,
    show,
    status,
    showReasonModal,
    title,
    imagePreviewModalShow,
    imageUrl,
    enable,
    docLabels,
    showModal,
    selectedBonus,
    bonusDetail,
    bonusAmount,
    basicInfo,
    kycInfo,
    contactInfo,
    freespinModal,
    showManageMoneyModal,
    showAddAffiliate,
    labelName,
    rolloverInfo,
    setShowAddAffiliate,
    setBonusAmount,
    issueBonusHandler,
    setSelectedBonus,
    setShowModal,
    setSelectedTab,
    handleVerify,
    updateDocument,
    setShow,
    handleYes,
    handleClose,
    setEnable,
    handleReRequest,
    setImagePreviewModalShow,
    handleImagePreview,
    setImageUrl,
    setShowManageMoneyModal,
    setFreeSpinModal,
    setIsInternalModal,
    isInternalModal,
    internalPlayerHandler,
    name,
    addUserAffiliateLoading,
    showRemoveAffiliate,
    updateUserAffiliateLoading,
    removeAffiliateHandler,
    setShowRemoveAffiliate,
    addAffiliateHandler,
    navigate,
    isActiveInactivemodalVisible,
    setActiveInactiveModal,
    updateUserStatus,
    verifyEmailModal,
    setVerifyEmailModal,
    handleVerifyEmail,
    setPasswordEmailModal,
    setUserPasswordModal,
    userPasswordModal,
    passwordEmailModal,
    handleResetPasswordEmail,
    handleResetUserPassword,
    editUserModal,
    setEditUserModal,
    limit,
    pageNo,
    setLimit,
    setPageNo,
    showTagModal,
    showDuplicateModal,
    setShowTagModal,
    setShowDuplicateModal,
    updateTags,
    tagOptions,
    setTagOptions,
    selectedTag,
    setSelectedTag,
    addTag,
    getDuplicateUsers,
    duplicateUsers,
    bonusLoading,
    setBonusLoading,
    sendLoyaltyPointsToUserModal,
    setSendLoyaltyPointsToUserModal,
    handleAddLoyaltyPointsToUser,
    handleLogoutUser,
    logoutUserLoading,
    getUserDocuments,
    updateUserStatusLoading,
  }
}

export default useUserDetails
