export const tableHeaders = [
  { label: 'Imagem', value: 'imageUrl' },
  { label: 'ID', value: 'gameSubCategoryId' },
  { label: 'Nome', value: 'name' },
  { label: 'Categoria de jogo', value: 'categoryname' },
  // { label: 'Image Url', value: 'imageUrl' },
  { label: 'Status', value: 'status' },
  { label: 'Global', value: 'isGlobal' },
  { label: 'Actions', value: 'Actions' }

]
